import React, { useEffect, useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { TextField, Button, Avatar, Backdrop, CircularProgress, Box } from '@mui/material';
import * as Yup from 'yup';
import "../css/profile.css";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AURCARDAPIBASE } from '../Utility/config';
import placeholder_image from "../images/placeholder_image.png";
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { Grid } from '@mui/material';

const Profile = () => {
    const [profileData, setProfileData] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const profileSchema = Yup.object({
        name: Yup.string().required(t('Name is required')),
        email: Yup.string()
            .email(t('Please enter a valid email address'))
            .required(t('Email is required')),
        phone: Yup.string()
            .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, t("Phone number is not valid"))
            .required(t("phone Is Required")),
        company: Yup.string(),
    });

    const fetchProfileData = async () => {
        setLoading(true);
        const authToken = localStorage.getItem('auth_token');
        if (!authToken) {
            toast.error("Authentication token is missing.");
            setLoading(false);
            navigate('/login')
            return;
        }

        try {
            const response = await axios.get(`${AURCARDAPIBASE}/profile`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            setProfileData(response.data.data || {});
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(`Error fetching profile data: ${error.response?.data?.message || error.message}`);
        }
    };

    useEffect(() => {
        fetchProfileData();
    }, []);




    const upadteProfileData = async (values) => {
        setLoading(true);
        const authToken = localStorage.getItem('auth_token');
        if (!authToken) {
            toast.error("Authentication token is missing.");
            setLoading(false);
            navigate('login');
            return;
        }
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
        };
        try {
            const response = await axios.post(`${AURCARDAPIBASE}/profile`, values, { headers });
            if (response?.data?.success) {
                toast.success("Profile updated successfully");
                setLoading(false);
            } else {
                toast.error("Failed to update");
                setLoading(false);
            }
        } catch (error) {

            toast.error("Failed to update");
            setLoading(false);
        }
    }


    return (
        <div className="profile-container">
            <ToastContainer />
            <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Formik
                enableReinitialize
                initialValues={{
                    profile_pic: profileData?.profile_pic || "",
                    name: profileData?.name || '',
                    email: profileData?.email || '',
                    phone: profileData?.phone || '',
                    company: profileData?.company || '',
                }}
                validationSchema={profileSchema}
                onSubmit={(values) => {
                    upadteProfileData(values);
                }}
            >
                {({ setFieldValue, values, handleChange, errors, touched }) => (
                    <Form className="profile-form">

                        <Box textAlign="center">
                            <input
                                className="profile-avatar"
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="profilePictureInput"
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file) {
                                        const reader = new FileReader();
                                        reader.onload = () => {
                                            setFieldValue('profile_pic', reader.result);
                                        };
                                        reader.readAsDataURL(file);
                                    }
                                }}
                            />
                            <label htmlFor="profilePictureInput">
                                <Avatar
                                    src={values.profile_pic}
                                    alt={t('Profile Picture')}
                                    sx={{
                                        width: { xs: 90, sm: 140 },
                                        height: { xs: 90, sm: 140 },
                                        cursor: 'pointer',
                                    }}
                                />
                            </label>
                            <ErrorMessage name="profile_pic" component="div" className="error-message" />

                        </Box>

                        <Grid container spacing={2} mt={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label={t("First Name")}
                                    variant="outlined"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    margin="normal"
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label={t("Email")}
                                    variant="outlined"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    margin="normal"
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label={t("Phone")}
                                    variant="outlined"
                                    name="phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    margin="normal"
                                    error={touched.phone && Boolean(errors.phone)}
                                    helperText={touched.phone && errors.phone}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label={t("Company Name (Optional)")}
                                    variant="outlined"
                                    name="company"
                                    value={values.company}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                            </Grid>
                        </Grid>
                        <Button variant="contained" type="submit" fullWidth className="update-button">
                            {t("Update")}
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default Profile;
