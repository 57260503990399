import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBarComponent from './Common/NavBarComponent';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Login from './Screens/Login';
import Signup from './Screens/Signup';
import Home from './Screens/Home';
import PlansScreen from './Screens/PlansScreen';
import Profile from './Screens/Profile';
import CardScreen from './Screens/CardScreen';
import Groups from './Screens/Groups';
import GroupCards from './Screens/GroupCards';
import Arabic from "./languages/Arabic.json";
import English from "./languages/English.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { GoogleOAuthProvider } from "@react-oauth/google";
import CardScreen2 from './Screens/CardScreen2';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: English,
      },
      ar: {
        translation: Arabic,
      }

    },
    lng: localStorage.getItem("lang"), // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

const App = () => {
  const [lang, setLang] = useState("en");



  useEffect(() => {
    if (
      localStorage.getItem("lang") === "ar" ||
      localStorage.getItem("lang") === "fa"
    ) {
      document.body.classList.add("arabic-font");
      document.body.classList.remove("latino-font");

      setLang("arabic-font");
    } else {
      document.body.classList.add("latino-font");
      document.body.classList.remove("arabic-font");
      setLang("latino-font");
    }
  }, [localStorage.getItem("lang")]);

  return (
    <GoogleOAuthProvider clientId="987046823583-0i3q2mn69kg3qgr838jb0hes433vn67e.apps.googleusercontent.com">
      <Router>
        <div className="App alignByLang">
          <NavWrapper />
          <Routes>

            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/" element={<Home />} />
            <Route path="/plans" element={<PlansScreen />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/groups" element={<Groups />} />
            {/* <Route path="/:lang?/qr" element={<CardScreen />} />
            <Route path="/:lang?/qr/:slug" element={<CardScreen />} /> */}
            <Route path="/:lang?/qr" element={<CardScreen2 />} />
            <Route path="/:lang?/qr/:slug" element={<CardScreen2 />} />

            <Route path="/groups/group" element={<GroupCards />} />

          </Routes>

        </div>
      </Router>
    </GoogleOAuthProvider>

  );
};

const NavWrapper = () => {
  const location = useLocation();

  // if (location.pathname === '/login' || location.pathname === '/signup' || location.pathname.startsWith('/qr')) {
  //   return null;
  // }

  if (
    location.pathname === '/login' ||
    location.pathname === '/signup' ||
    /^\/([a-z]{2}\/)?qr/.test(location.pathname)
  ) {
    return null;
  }

  return <NavBarComponent />;
};

export default App;

