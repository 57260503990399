import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Box, MenuItem, Grid, Typography } from '@mui/material';
import QRCodeStyling from 'qr-code-styling';
import "../../../css/CreateCardStepper.css";
import { t } from 'i18next';

const QRCodeConfigForm = ({ onSave, cardData, handleBack  }) => {
  const [formData, setFormData] = useState({
    qr_dots_color: cardData?.qrcodeconfig?.qr_dots_color || '#000000',
    qr_square_color: cardData?.qrcodeconfig?.qr_square_color || '#000000',
    qr_dot_style: cardData?.qrcodeconfig?.qr_dot_style || 'dots',
    qr_square_style: cardData?.qrcodeconfig?.qr_square_style || 'square',
    qr_dot_square_style: cardData?.qrcodeconfig?.qr_dot_square_style || 'square',
    qr_size: cardData?.qrcodeconfig?.qr_size || 200,
    qr_logo: cardData?.qrcodeconfig?.qr_logo || cardData?.basicinfo?.logo || "",
  });

  const qrCodeRef = useRef(null);

  const dotStyles = ['rounded', 'dots', 'classy', 'classy-rounded', 'square', 'extra-rounded'];
  const squareStyles = ['dot', 'square', 'extra-rounded'];
  const dotSquareStyles = ['dot', 'square'];
  const qrSizes = [100, 150, 200, 250, 300];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    onSave(formData);
  };



  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prev) => ({ ...prev, qr_logo: reader.result }));
      };
      reader.readAsDataURL(file); // Convert file to base64
    }
  };


  useEffect(() => {
    if (!qrCodeRef.current) {
      qrCodeRef.current = new QRCodeStyling({
        width: formData.qr_size,
        height: formData.qr_size,
        data: 'https://example.com',
        dotsOptions: {
          color: formData.qr_dots_color,
          type: formData.qr_dot_style,
        },
        cornersSquareOptions: {
          color: formData.qr_square_color,
          type: formData.qr_square_style,
        },
        cornersDotOptions: {
          color: formData.qr_dot_square_style,
          type: formData.qr_dot_square_style,
        },
        image: formData.qr_logo || null,
        imageOptions: formData.qr_logo
          ? {
            src: formData.qr_logo || null,
            width: formData.qr_size / 5,
            height: formData.qr_size / 5,
            x: formData.qr_size / 2 - formData.qr_size / 10,
            y: formData.qr_size / 2 - formData.qr_size / 10,
            crossOrigin: "anonymous"
          }
          : { hideBackgroundDots: true, imageSize: 0.4, margin: 0 },
      });

      qrCodeRef.current.append(document.getElementById('qrCodeContainer'));
    } else {
      qrCodeRef.current.update({
        dotsOptions: {
          color: formData.qr_dots_color,
          type: formData.qr_dot_style,
        },
        cornersSquareOptions: {
          color: formData.qr_square_color,
          type: formData.qr_square_style,
        },
        cornersDotOptions: {
          color: formData.qr_dot_square_style,
          type: formData.qr_dot_square_style,
        },
        width: formData.qr_size,
        height: formData.qr_size,
        image: formData.qr_logo || null,
        imageOptions: formData.qr_logo
          ? {
            src: formData.qr_logo || null,
            width: formData.qr_size / 5,
            height: formData.qr_size / 5,
            x: formData.qr_size / 2 - formData.qr_size / 10,
            y: formData.qr_size / 2 - formData.qr_size / 10,
            crossOrigin: "anonymous"
          }
          : { hideBackgroundDots: true, imageSize: 0.4, margin: 0 },
      });
    }
  }, [formData]);

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>QR Code Configuration</Typography>

          <TextField
            select
            label={t("QR Size")}
            name="qr_size"
            value={formData.qr_size}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            {qrSizes.map((size) => (
              <MenuItem key={size} value={size}>
                {size} px
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label={t("QR Dots Color")}
            name="qr_dots_color"
            value={formData.qr_dots_color}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
                <input
                  type="color"
                  value={formData.qr_dots_color}
                  onChange={(e) => setFormData((prev) => ({ ...prev, qr_dots_color: e.target.value }))}
                  style={{ width: 50, height: 30, border: 'none' }}
                />
              ),
            }}
          />

          <TextField
            label={t("QR Square Color")}
            name="qr_square_color"
            value={formData.qr_square_color}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
                <input
                  type="color"
                  value={formData.qr_square_color}
                  onChange={(e) => setFormData((prev) => ({ ...prev, qr_square_color: e.target.value }))}
                  style={{ width: 50, height: 30, border: 'none' }}
                />
              ),
            }}
          />

          <TextField
            select
            label={t("Dot Style")}
            name="qr_dot_style"
            value={formData.qr_dot_style}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            {dotStyles.map((style) => (
              <MenuItem key={style} value={style}>
                {style}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            label={t("Square Style")}
            name="qr_square_style"
            value={formData.qr_square_style}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            {squareStyles.map((style) => (
              <MenuItem key={style} value={style}>
                {style}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            label={t("Dot Square Style")}
            name="qr_dot_square_style"
            value={formData.qr_dot_square_style}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            {dotSquareStyles.map((style) => (
              <MenuItem key={style} value={style}>
                {style}
              </MenuItem>
            ))}
          </TextField>

          <Button
            variant="contained"
            component="label"
            fullWidth
            margin="normal"
          >
            {t("Upload Logo")}
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleLogoChange}
            />
          </Button>
        </Grid>

        <Grid item xs={12} md={6} container justifyContent="center" alignItems="center">
          <Box id="qrCodeContainer" />
        </Grid>
      </Grid>

      <div className='step-button-container'>
        <Button
          onClick={() => handleBack()}
          variant="contained"
          color="primary"
          sx={{ mt: 2, alignSelf: "right" }}
        >
          {t("Back")}
        </Button>
        <Button
          onClick={handleSubmit}
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2, alignSelf: "center" }}
        >
          {t("Finish")}
        </Button>
      </div>
    </Box>
  );
};

export default QRCodeConfigForm;
