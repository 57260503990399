import React, { useEffect, useRef, useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import {
  TextField,
  Button,
  MenuItem,
  IconButton,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
} from "@mui/material";
import {
  Facebook,
  Google,
  Apple,
  Language,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../css/login.css";
import login_image_card from "../images/login_image_card.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AURCARDAPIBASE } from "../Utility/config";
import i18next, { t } from "i18next";
import { useNavigate, useSearchParams } from "react-router-dom";



const Login = () => {
  const [language, setLanguage] = React.useState("en");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPasswordField, setShowPasswordField] = useState(false);


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [openVerifyEmailDialog, setOpenVerifyEmailDialog] = useState(false);
  const [openChangePasswordDialog, setChangePasswordDialog] = useState(false);
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const googleDiv = useRef(null);


  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t("Please enter a valid email address"))
      .required(t("Email is required")),
    password: Yup.string()
      .min(6, t("Password must be at least 6 characters"))
      .required(t("Password is required")),
  });

  useEffect(() => {
    if (localStorage.getItem("auth_token")) {
      navigate("/");
    }
    if (params.get("email") && params.get("token")) {
      setChangePasswordDialog(true);
    }
  }, []);

  // Handle language change
  const handleLanguageChange = (event) => {
    setLanguage(event.target.getAttribute("data-value"));
    i18next.changeLanguage(event.target.getAttribute("data-value"));
    document.querySelector("html").lang =
      event.target.getAttribute("data-value");
    localStorage.setItem("lang", event.target.getAttribute("data-value"));

    if (
      event.target.getAttribute("data-value") === "ar" ||
      event.target.getAttribute("data-value") === "fa"
    ) {
      document.body.classList.remove("latino-font");
      document.body.classList.add("arabic-font");
    } else {
      document.body.classList.add("latino-font");
      document.body.classList.remove("arabic-font");
    }
    setAnchorEl(null);
  };

  // Open language menu
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  const handleForgetPassword = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.trim())) {
      toast.error(t("Enter Valid Email"));
      return;
    }

    axios.post(`${AURCARDAPIBASE}/forgot-password/send-email`, {
      'email': email
    }, {
      'Content-Type': 'application/json',
    })
      .then((response) => {
        if (response?.data?.success) {
          setOpenVerifyEmailDialog(false);
          // toast.success(t("Check your email inbox please"));
          toast.success(t(response?.data?.message));

        } else {
          toast.error(t(response?.data?.message));
        }
      })
      .catch((error) => {

        toast.error(t("Error while sending email"));
      });

  };

  const handleChangePassword = () => {
    if (password !== confirmPassword) {
      toast.error(t("password not match the confirm password!"));
    } else {

      axios.post(`${AURCARDAPIBASE}/forgot-password/update-password`, {
        'token': params.get("token"),
        'password': password
      }, {
        'Content-Type': 'application/json',
      })
        .then((response) => {
          if (response?.data?.success) {

            // toast.success(t("Password has been changed!"));
            toast.success(t(response?.data?.message));
            params.delete("email");
            params.delete("token");
            setParams(params);
            setChangePasswordDialog(false)
          } else {
            toast.error(t(response?.data?.message));
          }
        })
        .catch((error) => {
          toast.error(t("Error while sending email"));
        });
      ;
    }
  };

  const login = async (values) => {
    try {
      const response = await axios.post(`${AURCARDAPIBASE}/login`, values);
      if (response?.data?.success) {
        const { token } = response?.data?.data;
        localStorage.setItem("auth_token", token);
        toast.success("Login successfully");
        navigate("/");
      } else {
        toast.error("Login failed");
      }
    } catch (error) {
      toast.error("Login failed");
    }
  };

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);


  const toggleShowPasswordField = () =>
    setShowPasswordField(!showPasswordField);


  const handleGoogleLogin = async (data, id) => {
    try {
      const googleInfo = {
        first_name: data.given_name,
        last_name: data.family_name,
        email: data.email,
      };
      console.log("googleData",data)

      // const promise = await axios.post(
      //   `${process.env.REACT_APP_API}/social-login`,
      //   googleInfo
      // );


      // if (promise.status === 200 && promise.data.success) {
      //   localStorage.setItem("type", "user");
      //   localStorage.setItem("jwt_authorization", promise.data.data.token);
      //   localStorage.setItem("name", promise.data.data.name);
      //   localStorage.setItem("email", promise.data.data.email);
      //   localStorage.setItem("logo", promise.data.data.logo);
      // } else {
      //   toast.error(promise.data.message);
      // }
    } catch (error) {
      toast.error(t("Error occurred") + error,);
    }
  };



  return (
    <div className="login alignByLang">
      <ToastContainer />
      <div className="login-container login-bk-image">
        <div className="login-image">
          <img src={login_image_card} alt="Image_Card" />
        </div>
        <div className="login-form">
          <div className="login-form-container">
            <div className="login-header">
              <h2>{t("Login")}</h2>
              <div className="language-dropdown">
                <IconButton
                  onClick={handleMenuOpen}
                  aria-controls="language-menu"
                  aria-haspopup="true"
                >
                  <Language sx={{ fontSize: 30 }} />
                </IconButton>
                <Menu
                  id="language-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleLanguageChange} data-value="en">
                    English
                  </MenuItem>
                  <MenuItem onClick={handleLanguageChange} data-value="ar">
                    Arabic
                  </MenuItem>
                </Menu>
              </div>
            </div>
            <p className="welcome-message">
              {t("Welcome back! Please enter your details.")}
            </p>

            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={validationSchema}
              onSubmit={login}
            >
              {({ handleChange, handleBlur, values }) => (
                <Form>
                  <div className="form-group">
                    <Field
                      as={TextField}
                      fullWidth
                      label={t("Email")}
                      variant="outlined"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!values.email && !!values.emailError}
                      helperText={<ErrorMessage name="email" />}
                      margin="normal"
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      as={TextField}
                      fullWidth
                      label={t("Password")}
                      variant="outlined"
                      name="password"
                      type={showPasswordField ? "text" : "password"}

                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!values.password && !!values.passwordError}
                      helperText={<ErrorMessage name="password" />}
                      margin="normal"

                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={toggleShowPasswordField} edge="end">
                              {showPasswordField ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <Button
                    onClick={() => setOpenVerifyEmailDialog(true)}
                    className="forgot-password-link"
                  >
                    {t("Forgot Password?")}
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    className="login-button"
                  >
                    {t("Login")}
                  </Button>
                </Form>
              )}
            </Formik>

            <div className="social-login-buttons">
              {/* <IconButton
                color="primary"
                aria-label="facebook"
                sx={{ fontSize: 30 }}
              >
                <Facebook />
              </IconButton> */}


              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  const data = jwtDecode(credentialResponse.credential);
                  handleGoogleLogin(data, credentialResponse.clientId);
                }}
                onError={() => {
                  console.log("Login Failed");
                }}

                render={({ onClick }) => (
                  <IconButton color="primary" aria-label="google" sx={{ fontSize: 30 }} onClick={onClick}>
                    <Google />
                  </IconButton>
                )}
                shape="square"
                size="large"
                text={"signin"}
                locale={localStorage.getItem("lang")}
              />
              {/* <IconButton
                color="primary"
                aria-label="apple"
                sx={{ fontSize: 30 }}
              >
                <Apple />
              </IconButton> */}
            </div>

            {/* Forgot Password and Register Links */}

            <div className="extra-links">
              <Button href="/signup" className="register-link">
                {t("No Account Yet? Register")}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Dialog className="alignByLang" fullWidth open={openVerifyEmailDialog}>
        <DialogTitle>{t("Verify Email")}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)} // Correctly access the input value
            placeholder={t("Enter Your Email")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenVerifyEmailDialog(false)}>
            {t("Cancel")}
          </Button>
          <Button onClick={() => handleForgetPassword()}>{t("Verify")}</Button>
        </DialogActions>
      </Dialog>

      <Dialog className="alignByLang" fullWidth open={openChangePasswordDialog}>
        <DialogTitle>{t("Change Password")}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t("Enter your password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggleShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            className="mt-3"
            fullWidth
            type={showConfirmPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder={t("Confirm password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggleShowConfirmPassword} edge="end">
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setChangePasswordDialog(false)}>
            {t("Cancel")}
          </Button>
          <Button onClick={() => handleChangePassword()}>{t("Change")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Login;
