
import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
  Typography,
  Divider,
  Box,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import PlanIcon from '@mui/icons-material/Description';
import ProfileIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import LanguageIcon from '@mui/icons-material/Language';
import logo_icon from '../../images/aurages.png';
import { useNavigate, useSearchParams } from 'react-router-dom';
import i18next from 'i18next';
import { t } from 'i18next';

const NavBarComponent = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
  const [language, setLanguage] = useState('en');
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const toggleDrawer = (open) => () => setDrawerOpen(open);

  const handleLanguageClick = (event) => setLanguageAnchorEl(event.currentTarget);

  const handleLanguageClose = () => setLanguageAnchorEl(null);

  const handleLanguageChange = (event) => {
    const lang = event.target.getAttribute('data-value');
    setLanguage(lang);
    i18next.changeLanguage(lang);
    document.querySelector('html').lang = lang;
    localStorage.setItem('lang', lang);
    params.set('lang', lang);
    setParams(params);

    document.body.classList.toggle('arabic-font', lang === 'ar');
    document.body.classList.toggle('latino-font', lang !== 'ar');
    setLanguageAnchorEl(null);
    if (drawerOpen) {
      setDrawerOpen(false);
    }
  };

  const handleCloseDrawerAfterClick = (path = "/") => {
    navigate(path);
    if (drawerOpen) {
      setDrawerOpen(false);
    }
  }

  const navItems = [
    { text: t('Home'), icon: <HomeIcon />, onClick: () => handleCloseDrawerAfterClick('/') },
    { text: t('Groups'), icon: <GroupIcon />, onClick: () => handleCloseDrawerAfterClick('/groups') },
    { text: t('Plans'), icon: <PlanIcon />, onClick: () => handleCloseDrawerAfterClick('/plans') },
    { text: t('My Profile'), icon: <ProfileIcon />, onClick: () => handleCloseDrawerAfterClick('/profile') },
  ];

  useEffect(() => {
    const auth_token = localStorage.getItem('auth_token');
    if (!auth_token) {
      navigate('/login');
    }
  }, []);

  const handleLogOut = () => {
    localStorage.removeItem('auth_token');
    navigate('/login');
    if (drawerOpen) {
      setDrawerOpen(false);
    }
  };

  return (
    <div className="alignByLang">
      <AppBar color="" position="static">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <IconButton edge="start" aria-label="logo" sx={{ mr: 2 }}>
            <img src={logo_icon} alt={t('Logo')} style={{ width: 100, height: 40 }} />
          </IconButton>

          {!isSmallScreen && (
            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }}>
              <Box>
                {navItems.map((item) => (
                  <Button
                    key={item.text}
                    color="inherit"
                    onClick={item.onClick}
                    sx={{ textTransform: 'none', mx: 1 }}
                  >
                    {item.text}
                  </Button>
                ))}
                <Button
                  color="inherit"
                  onClick={handleLanguageClick}
                  sx={{ textTransform: 'none', mx: 1 }}
                >
                  {t('Language')}
                </Button>
                <Menu
                  className='alignByLang'
                  anchorEl={languageAnchorEl}
                  open={Boolean(languageAnchorEl)}
                  onClose={handleLanguageClose}

                >
                  <MenuItem onClick={handleLanguageChange} data-value="en">English</MenuItem>
                  <MenuItem onClick={handleLanguageChange} data-value="ar">Arabic</MenuItem>
                </Menu>
              </Box>
              <Button color="inherit" onClick={handleLogOut} >
                {t('Logout')}
              </Button>
            </Box>
          )}

          {isSmallScreen && (
            <IconButton edge="end" color="inherit" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      <Drawer anchor={localStorage.getItem("lang") === "ar" || localStorage.getItem("lang") === "fa" ? "right" : "left"} open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 250 }}>
          <Box sx={{ p: 2, textAlign: 'center' }}>
            <img src={logo_icon} alt={t('Logo')} style={{ width: 120, height: 50 }} />
          </Box>
          <Divider />
          <List >
            {navItems.map((item) => (
              <ListItem className='alignByLang' button key={item.text} onClick={item.onClick}>
                {item.icon}
                <ListItemText sx={localStorage.getItem("lang") === "ar" || localStorage.getItem("lang") === "fa" ? { mr: 2 } : { ml: 2 }} primary={item.text} />
              </ListItem>
            ))}
            <ListItem className='alignByLang' button onClick={handleLanguageClick}>
              <LanguageIcon />
              <ListItemText sx={localStorage.getItem("lang") === "ar" || localStorage.getItem("lang") === "fa" ? { mr: 2 } : { ml: 2 }} primary={t('Language')} />
            </ListItem>
            <Menu
              className="alignByLang"
              anchorEl={languageAnchorEl}
              open={Boolean(languageAnchorEl)}
              onClose={handleLanguageClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: localStorage.getItem("lang") === "ar" || localStorage.getItem("lang") === "fa" ? "right" : "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: localStorage.getItem("lang") === "ar" || localStorage.getItem("lang") === "fa" ? "right" : "left",
              }}
            >
              <MenuItem onClick={handleLanguageChange} data-value="en">English</MenuItem>
              <MenuItem onClick={handleLanguageChange} data-value="ar">Arabic</MenuItem>
            </Menu>
            <ListItem className='alignByLang' button onClick={handleLogOut}>
              <LogoutIcon />
              <ListItemText sx={localStorage.getItem("lang") === "ar" || localStorage.getItem("lang") === "fa" ? { mr: 2 } : { ml: 2 }} primary={t('Logout')} />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </div>
  );
};

export default NavBarComponent;

