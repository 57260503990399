import { Avatar, Box, DialogContent, IconButton, Typography, Button, Backdrop, CircularProgress, Stack, Slide } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { AURCARDAPIBASE, getBase64OfImage, getSvgImageUri } from "../Utility/config";
import { useParams } from "react-router-dom";
import placeholder_image from "../images/placeholder_image.png";
import theme1 from "../images/theme1.jpg";
import theme2 from "../images/theme2.jpg";
import theme3 from "../images/theme3.jpg";
import theme4 from "../images/theme4.jpg";
import theme5 from "../images/theme5.jpg";
import theme6 from "../images/theme6.jpg";
import theme7 from "../images/theme7.jpg";
import theme8 from "../images/theme8.jpg";
import theme9 from "../images/theme9.jpg";
import theme10 from "../images/theme10.jpg";
import logo from "../images/aurages.png";



const CardScreen2 = () => {
    const [cardData, setCardData] = useState(null);
    const [loading, setLoading] = useState(false);
    const { slug } = useParams();
    const [imageSrcData, setImageSrcData] = useState("");
    const [showWorkInfo, setShowWorkInfo] = useState(false);
    const [showAboutMe, setShowAboutMe] = useState(false);
    useEffect(() => {
        if (slug) {
            setLoading(true);
            const authToken = localStorage.getItem('auth_token');
            axios
                .get(`${AURCARDAPIBASE}/qr/${slug}`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                })
                .then((response) => {
                    setCardData(response?.data?.data);
                    getBase64OfImage(response?.data?.data?.basicinfo?.cover || "").then((base64) => {
                        setImageSrcData(base64)
                    });
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error fetching card data:', error);
                    setCardData(window.cardData);
                    setLoading(false);
                });
        } else {
            setCardData(window.cardData);
        }
    }, []);

    const alignment = cardData?.cardstyle?.avatar_align || 'center';

    const handleOpenContact = (contact) => {
        if (!contact?.href_prefix) {
            window.open(`${contact.link}`, '_blank');
        } else {
            window.open(`${contact.href_prefix}${contact.link}`, '_blank');

        }
    };

    const handleSaveContact = () => {
        let vCardContent = '';

        vCardContent += `BEGIN:VCARD\n`;
        vCardContent += `VERSION:3.0\n`;

        if (cardData?.basicinfo?.name) {
            vCardContent += `FN:${cardData.basicinfo.name}\n`;
        }

        if (cardData?.basicinfo?.job_title) {
            vCardContent += `TITLE:${cardData.basicinfo.job_title}\n`;
        }

        if (cardData?.basicinfo?.avatar) {
            vCardContent += `PHOTO;VALUE=URI:${cardData.basicinfo.avatar}\n`;
        }

        cardData?.contactway.forEach((contact) => {
            if (contact.name === "Phone") {
                vCardContent += `TEL:${contact.href_prefix}${contact.link}\n`;
            } else if (contact.name === "Email") {
                vCardContent += `EMAIL:${contact.link}\n`;
            } else if (contact.name === "Website" || contact.name === "Linked In" || contact.name === "Telegram" || contact.name === "X" || contact.name === "Instagram") {
                vCardContent += `URL:${contact.link}\n`;
            }
        });

        vCardContent += `END:VCARD\n`;

        const blob = new Blob([vCardContent], { type: 'text/vcard' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'contact_info.vcf';
        link.click();
    };

    const toggleWorkInfo = () => {
        setShowWorkInfo((prev) => !prev);
    };

    const toggleAboutMe = () => {
        setShowAboutMe((prev) => !prev);
    };
    return (
        <div>
            <Box
                sx={{
                    backgroundColor: cardData?.cardstyle?.background_color || '#ffffff',
                    width: '100%',
                    height: '100vh',
                    // display:'flex'
                }}
            >
                <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                {!loading &&

                    cardData?.cardstyle?.card_theme ?
                    <Box
                        sx={{
                            width: "100vw",
                            backgroundImage: `url("/themes${cardData?.cardstyle?.theme_type === "static" ? "/" : "/animation/"}${cardData?.cardstyle?.card_theme}.${cardData?.cardstyle?.theme_type === "static" ? "jpg" : "gif"}")`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Box
                            sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.2)", // Adjust overlay opacity
                                zIndex: 1,
                            }}
                        />

                        <Box
                            sx={{
                                zIndex: 2,
                                textAlign: "center",
                                color: "#fff",
                                padding: "20px",
                                borderRadius: "8px",
                                maxWidth: "400px",
                            }}
                        >
                            {/* Avatar */}
                            <Box
                                sx={{
                                    width: "80px",
                                    height: "80px",
                                    backgroundColor: "#fff",
                                    borderRadius: "50%",
                                    margin: "0 auto",
                                    marginBottom: "16px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >

                                <Avatar
                                    src={cardData?.basicinfo?.avatar || placeholder_image}
                                    alt="Profile"
                                    sx={{
                                        width: { xs: 100, sm: 100, md: 100 },
                                        height: { xs: 100, sm: 100 },
                                        border: `3px solid #FFFFFF}`,
                                        borderRadius: '50%',
                                    }}
                                />
                            </Box>

                            <Typography variant="h4" fontWeight="bold">
                                {cardData?.basicinfo?.prefix || ""}{" "}{cardData?.basicinfo?.name || ""}{" "}{cardData?.basicinfo?.suffix || ""}
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: "5px" }}>
                                {cardData?.basicinfo?.job_title || ""}{cardData?.basicinfo?.company ? " @ " : ""}{cardData?.basicinfo?.company || ""}
                            </Typography>

                            {cardData?.basicinfo?.preferred_name && (
                                <Typography variant="body1" sx={{ marginBottom: "5px" }}>
                                    {"(" + cardData?.basicinfo?.preferred_name + ")" || ""}
                                </Typography>
                            )}

                            <Stack spacing={2} sx={{ marginTop: 3 }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                                        padding: "12px 20px",
                                        color: "#fff",
                                        "&:hover": {
                                            backgroundColor: "rgba(255, 255, 255, 0.3)",
                                        },
                                    }}
                                    onClick={toggleAboutMe}
                                >
                                    {showAboutMe ? "Hide About Me" : "About Me"}
                                </Button>

                                {showAboutMe && (
                                    <Box
                                        sx={{
                                            padding: "16px",
                                            backgroundColor: "rgba(0, 0, 0, 0.7)",
                                            borderRadius: "8px",
                                            marginTop: "16px",
                                        }}
                                    >
                                        <Typography variant="h6" color="#fff">
                                            About Me
                                        </Typography>
                                        <Typography variant="body2" color="#ccc">
                                            {cardData?.basicinfo?.bio || "No work info available."}
                                        </Typography>

                                    </Box>
                                )}
                            </Stack>

                            <Stack spacing={2} sx={{ marginTop: 3 }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                                        padding: "12px 20px",
                                        color: "#fff",
                                        "&:hover": {
                                            backgroundColor: "rgba(255, 255, 255, 0.3)",
                                        },
                                    }}
                                    onClick={toggleWorkInfo}
                                >
                                    {showWorkInfo ? "Hide Work Info" : "Work Info"}
                                </Button>

                                {showWorkInfo && (
                                    <Box
                                        sx={{
                                            padding: "16px",
                                            backgroundColor: "rgba(0, 0, 0, 0.7)",
                                            borderRadius: "8px",
                                            marginTop: "16px",
                                        }}
                                    >
                                        <Typography variant="h6" color="#fff">
                                            Work Info
                                        </Typography>

                                        {cardData?.basicinfo?.company && (
                                            <Typography variant="body1" sx={{ marginBottom: "5px" }}>
                                                Company : {cardData?.basicinfo?.company || ""}
                                            </Typography>
                                        )}

                                        {cardData?.basicinfo?.department && (
                                            <Typography variant="body1" sx={{ marginBottom: "5px" }}>
                                                Department : {cardData?.basicinfo?.department || ""}
                                            </Typography>
                                        )}

                                        {cardData?.basicinfo?.job_title && (

                                            <Typography variant="body1" sx={{ marginBottom: "5px" }}>
                                                Job Title : {cardData?.basicinfo?.job_title || ""}
                                            </Typography>
                                        )}


                                    </Box>
                                )}
                            </Stack>


                            <Stack spacing={2} sx={{ marginTop: 3 }}>
                                {cardData?.contactway?.length > 0 && (
                                    <Box >
                                        <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
                                            <Button
                                                fullWidth
                                                onClick={handleSaveContact}
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                                                    padding: "12px 20px",
                                                    color: "#fff",
                                                    "&:hover": {
                                                        backgroundColor: "rgba(255, 255, 255, 0.3)",
                                                    },
                                                }}
                                            >
                                                Save Contact
                                            </Button>
                                        </Box>

                                        <DialogContent
                                            sx={{
                                                padding: 2,
                                                height: '300px', // Fixed height for the scrollable container
                                                overflowY: 'auto', // Enable vertical scrolling
                                                '&::-webkit-scrollbar': {
                                                    width: '8px',
                                                },
                                                '&::-webkit-scrollbar-thumb': {
                                                    backgroundColor: 'rgba(255, 255, 255, 0.3)', // Custom scrollbar color
                                                    borderRadius: '4px',
                                                },
                                                '&::-webkit-scrollbar-thumb:hover': {
                                                    backgroundColor: 'rgba(255, 255, 255, 0.5)', // Hover effect for scrollbar
                                                },
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns:
                                                        cardData?.contactway?.length < 3
                                                            ? `repeat(${cardData.contactway.length}, 1fr)`
                                                            : { xs: 'repeat(3, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(auto-fill, minmax(120px, 1fr))' },
                                                    gap: { xs: 2, sm: 5 },
                                                    justifyContent: cardData?.contactway?.length < 3 ? 'center' : 'start',
                                                    maxWidth: { xs: 300, sm: 500 },
                                                    margin: '0 auto',
                                                }}
                                            >
                                                {cardData?.contactway?.map((contact) => (
                                                    <Box
                                                        key={contact.name}
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            textAlign: 'center',
                                                            padding: { xs: 1, sm: 2 },
                                                            borderRadius: '12px',
                                                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                                                            backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                                            color: '#fff',
                                                            transition: 'transform 0.3s, background-color 0.3s',
                                                            '&:hover': {
                                                                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                                                transform: 'scale(1.05)',
                                                                cursor: 'pointer',
                                                            },
                                                        }}
                                                        onClick={() => handleOpenContact(contact)}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width: '60px',
                                                                height: '60px',
                                                                borderRadius: '50%',
                                                                overflow: 'hidden',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                marginBottom: 1,
                                                            }}
                                                        >
                                                            <img
                                                                src={contact.icon || placeholder_image}
                                                                alt={contact.name}
                                                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                            />
                                                        </Box>
                                                        <Typography variant="body2">{contact.name}</Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </DialogContent>


                                    </Box>
                                )}
                            </Stack>


                        </Box>
                    </Box>
                    :
                    <>
                        {!cardData?.cardstyle?.style_shape ?
                            <>
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: { xs: 180, sm: 250 },
                                        backgroundImage: `url(${cardData?.basicinfo?.cover || placeholder_image})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        border: `2px solid ${cardData?.cardstyle?.cover_border_color || '#000000'}`,
                                        marginBottom: 6,
                                        position: 'relative',
                                    }}
                                >
                                    <Avatar
                                        src={cardData?.basicinfo?.avatar || placeholder_image}
                                        alt="Profile"
                                        sx={{
                                            width: { xs: 130, sm: 170, md: cardData?.cardstyle?.avatar_shape === 'rectangle' ? 190 : 170 },
                                            height: { xs: 130, sm: 170 },
                                            border: `3px solid ${cardData?.cardstyle?.avatar_border_color || '#000000'}`,
                                            borderRadius: cardData?.cardstyle?.avatar_shape === 'circle' ? '50%' : '0%',
                                            position: 'absolute',
                                            bottom: -65,
                                            left: alignment === 'left' ? 20 : alignment === 'center' ? '50%' : 'auto',
                                            right: alignment === 'right' ? 20 : 'auto',
                                            transform: alignment === 'center' ? 'translateX(-50%)' : 'none',
                                        }}
                                    />
                                </Box>

                                <Box sx={{ textAlign: alignment, marginTop: 10, }}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: cardData?.cardstyle?.text_color || '#000000', fontSize: 15 }}>
                                        {cardData?.basicinfo?.prefix || ""}{" "}{cardData?.basicinfo?.name || ""}{" "}{cardData?.basicinfo?.suffix || ""}
                                    </Typography>

                                    {cardData?.basicinfo?.preferred_name && (
                                        <Typography variant="h6" sx={{ color: cardData?.cardstyle?.text_color || '#000000', fontSize: 15 }}>
                                            {"(" + cardData?.basicinfo?.preferred_name + ")" || ""}
                                        </Typography>
                                    )}



                                    <Typography variant="h6" sx={{ color: cardData?.cardstyle?.text_color || '#000000', fontSize: 15 }}>
                                        {cardData?.basicinfo?.company || ""}
                                    </Typography>
                                    <Typography variant="h6" sx={{ color: cardData?.cardstyle?.text_color || '#000000', fontSize: 15 }}>
                                        {cardData?.basicinfo?.job_title || ""}{"-"}{cardData?.basicinfo?.department || ""}
                                    </Typography>
                                    <Typography variant="h6" sx={{ color: cardData?.cardstyle?.text_color || '#000000', fontSize: 15 }}>
                                        {cardData?.basicinfo?.bio || ""}
                                    </Typography>
                                </Box>
                            </>
                            :
                            <>
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: 300,
                                        backgroundImage: getSvgImageUri(imageSrcData || placeholder_image, cardData?.cardstyle?.style_shape, cardData?.cardstyle?.cover_border_color || "#00f"),
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        borderRadius: 0,
                                        border: `0px solid ${cardData?.cover_border_color}`,
                                        position: 'relative',
                                        padding: 1
                                    }}
                                >
                                    <Avatar
                                        src={cardData?.basicinfo?.avatar || placeholder_image}
                                        alt="Profile"
                                        sx={{
                                            width: cardData?.cardstyle?.style_shape ? 90 : 100,
                                            height: cardData?.cardstyle?.style_shape ? 90 : 100,
                                            border: `0px solid ${cardData?.cardstyle?.avatar_border_color}`,
                                            borderRadius: cardData?.cardstyle?.style_shape === "shape1" ? '7%' : cardData?.cardstyle?.style_shape === "shape2" ? '50%' : cardData?.cardstyle?.style_shape === "shape3" ? '50%' : cardData?.cardstyle?.style_shape === "shape4" ? '50%' : cardData?.cardstyle?.style_shape === "shape5" ? '7%' : '0%',
                                            position: 'absolute',
                                            bottom: cardData?.cardstyle?.style_shape === "shape1" ? 100 : cardData?.cardstyle?.style_shape === "shape2" ? 100 : cardData?.cardstyle?.style_shape === "shape3" ? 100 : cardData?.cardstyle?.style_shape === "shape4" ? -10 : cardData?.cardstyle?.style_shape === "shape5" ? 120 : "auto",//shap1
                                            left: cardData?.cardstyle?.style_shape === "shape1" ? 10 : cardData?.cardstyle?.style_shape === "shape2" ? 17 : cardData?.cardstyle?.style_shape === "shape3" ? 10 : 'auto',//shap1
                                            right: cardData?.cardstyle?.style_shape === "shape4" ? 20 : 'auto',
                                            transform: cardData?.avatar_align === 'center' ? 'translateX(-50%)' : 'none',
                                        }}
                                    />

                                    <Box sx={{
                                        textAlign: alignment,
                                        position: 'absolute',
                                        left: cardData?.cardstyle?.style_shape === "shape1" ? 0 : cardData?.cardstyle?.style_shape === "shape2" ? 0 : cardData?.cardstyle?.style_shape === "shape3" ? 0 : cardData?.cardstyle?.style_shape === "shape4" ? 0 : 'auto',
                                        bottom: cardData?.cardstyle?.style_shape === "shape1" ? -55 : cardData?.cardstyle?.style_shape === "shape2" ? -25 : cardData?.cardstyle?.style_shape === "shape3" ? -25 : cardData?.cardstyle?.style_shape === "shape4" ? -35 : cardData?.cardstyle?.style_shape === "shape5" ? -20 : "auto",//shap1
                                    }}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: cardData?.cardstyle?.text_color || '#000000', fontSize: 15 }}>
                                            {cardData?.basicinfo?.prefix || ""}{" "}{cardData?.basicinfo?.name || ""}{" "}{cardData?.basicinfo?.suffix || ""}
                                        </Typography>
                                        {cardData?.basicinfo?.preferred_name && (
                                            <Typography variant="h6" sx={{ color: cardData?.cardstyle?.text_color || '#000000', fontSize: 15 }}>
                                                {"(" + cardData?.basicinfo?.preferred_name + ")" || ""}
                                            </Typography>
                                        )}
                                        <Typography variant="h6" sx={{ color: cardData?.cardstyle?.text_color || '#000000', fontSize: 15 }}>
                                            {cardData?.basicinfo?.company || ""}
                                        </Typography>
                                        <Typography variant="h6" sx={{ color: cardData?.cardstyle?.text_color || '#000000', fontSize: 15 }}>
                                            {cardData?.basicinfo?.job_title || ""}{"-"}{cardData?.basicinfo?.department || ""}
                                        </Typography>
                                        <Typography variant="h6" sx={{ color: cardData?.cardstyle?.text_color || '#000000', fontSize: 15 }}>
                                            {cardData?.basicinfo?.bio || ""}
                                        </Typography>

                                    </Box>
                                </Box>

                            </>
                        }

                        {cardData?.contactway?.length > 0 && (
                            <Box sx={{ marginTop: !cardData?.cardstyle?.style_shape ? 1 : 8, textAlign: alignment }}>
                                <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSaveContact}
                                        sx={{ padding: 1.2, width: 200 }}
                                    >
                                        Save Contact
                                    </Button>
                                </Box>

                                <DialogContent sx={{ padding: 2 }}>
                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns:
                                                cardData?.contactway?.length < 3
                                                    ? `repeat(${cardData.contactway.length}, 1fr)` // Adjust to number of contacts
                                                    : { xs: 'repeat(3, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(auto-fill, minmax(120px, 1fr))' },
                                            gap: { xs: 2, sm: 5 },
                                            justifyContent: cardData?.contactway?.length < 3 ? 'center' : 'start',
                                            maxWidth: { xs: 300, sm: 500 },
                                            margin: '0 auto',
                                            marginBottom: 5

                                        }}
                                    >
                                        {cardData?.contactway?.map((contact) => (
                                            <Box
                                                key={contact.name}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    textAlign: 'center',
                                                    padding: { xs: 0.5, sm: 1 },
                                                    borderRadius: 2,
                                                    boxShadow: 2,
                                                    backgroundColor: '#f0f0f0',
                                                    '&:hover': {
                                                        backgroundColor: '#e0e0e0',
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                                onClick={() => handleOpenContact(contact)}
                                            >
                                                <IconButton>
                                                    <img src={contact.icon} alt={contact.name} style={{ width: 50, height: 50 }} />
                                                </IconButton>
                                                <Typography variant="body2" sx={{ marginTop: { xs: 0, sm: 1 } }}>
                                                    {contact.title || contact.name}
                                                </Typography>
                                            </Box>
                                        ))}

                                    </Box>
                                </DialogContent>

                            </Box>
                        )}

                    </>
                }

                <Box
                    sx={{
                        position: "fixed",
                        bottom: 4,
                        width: '100%',
                        textAlign: 'center',
                        padding: 1.5,
                        backgroundColor: '#f0f0f0',
                        color: '#000000',

                    }}
                >

                    <a href="https://aurages.com/" target="_blank" rel="noopener noreferrer">
                        <img style={{ width: 80, height: 20 }} src={logo} alt="Aurages" />
                    </a>
                </Box>

            </Box>

        </div>

    );
};

export default CardScreen2;
