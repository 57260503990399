import React, { useState } from "react";
import {
  Button,
  Box,
  Stack,
  Avatar,
  Typography,
  TextField,
  Grid,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import placeholder_image from "../../../images/placeholder_image.png";
import "../../../css/CreateCardStepper.css";
import { t } from "i18next";
import { Info, Warning } from "@mui/icons-material";
const BasicInfoForm = ({ onSave, cardData, from_group, group_data }) => {
  const [imageError, setImageError] = useState(null);
  const [disabledSlug, setDisabledSlug] = useState(true);
  const [openSlugDialog, setOpenSlugDialog] = useState(false);
  const formik = useFormik({
    initialValues: {
      avatar: cardData?.basicinfo?.avatar || "",
      cover: cardData?.basicinfo?.cover || "",
      logo: cardData?.basicinfo?.logo || "",
      name: cardData?.basicinfo?.name || "",
      prefix: cardData?.basicinfo?.prefix || "",
      suffix: cardData?.basicinfo?.suffix || "",
      preferred_name: cardData?.basicinfo?.preferred_name || "",
      company: from_group
        ? group_data.company
        : cardData?.basicinfo?.company || "",
      department: from_group
        ? group_data.department
        : cardData?.basicinfo?.department || "",
      job_title: cardData?.basicinfo?.job_title || "",
      bio: cardData?.basicinfo?.bio || "",
      slug: cardData?.basicinfo?.slug || "",
    },
    validationSchema: Yup.object({
      // avatar: Yup.mixed().required("Profile Picture is required"),
      // cover: Yup.mixed().required("Cover is required"),
      // logo: Yup.mixed().required("Logo is required"),
      name: Yup.string().required("Name is required"),
      slug: Yup.string().required("Sulg is required"),
    }),
    onSubmit: (values) => {
      onSave(values);
    },
  });

  const handleImageChange = (event, fieldName) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        formik.setFieldValue(fieldName, reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCloseSlugDialogWithContinue = () => {
    setDisabledSlug(false);
    setOpenSlugDialog(false);
  };
  return (
    <Box sx={{ padding: 2 }} component="form" onSubmit={formik.handleSubmit}>
      {imageError && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {imageError}
        </Alert>
      )}

      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Box textAlign="center">
          <Typography
            variant="subtitle1"
            sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
          >
            {t("Profile Picture")}
          </Typography>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="profilePictureInput"
            onChange={(e) => handleImageChange(e, "avatar")}
          />
          <label htmlFor="profilePictureInput">
            <Avatar
              src={formik.values.avatar}
              alt={t("Profile Picture")}
              sx={{
                width: { xs: 60, sm: 80 },
                height: { xs: 60, sm: 80 },
                cursor: "pointer",
              }}
            />
          </label>
        </Box>

        <Box textAlign="center">
          <Typography
            variant="subtitle1"
            sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
          >
            {t("Cover")}
          </Typography>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="coverInput"
            onChange={(e) => handleImageChange(e, "cover")}
          />
          <label htmlFor="coverInput">
            <Avatar
              // component="img"
              src={formik.values.cover}
              alt={t("Cover")}
              sx={{
                width: { xs: 200, sm: 300 },
                height: 80,
                objectFit: "cover",
                borderRadius: 1,
                backgroundColor: "#f0f0f0",
                cursor: "pointer",
              }}
            />
          </label>
        </Box>

        <Box textAlign="center">
          <Typography
            variant="subtitle1"
            sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
          >
            {t("Logo")}
          </Typography>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="logoInput"
            onChange={(e) => handleImageChange(e, "logo")}
          />
          <label htmlFor="logoInput">
            <Avatar
              src={formik.values.logo}
              alt={t("Logo")}
              sx={{
                width: { xs: 60, sm: 80 },
                height: { xs: 60, sm: 80 },
                cursor: "pointer",
              }}
            />
          </label>
        </Box>
      </Stack>

      <Grid  container spacing={2} mt={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("Name")}
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            fullWidth
          />
        </Grid>

        <Grid className="d-flex justify-content-between" item xs={12} sm={6}>
          <TextField
            disabled={cardData?.basicinfo?.slug ? disabledSlug : false}
            label={t("Slug")}
            name="slug"
            value={formik.values.slug}
            onChange={formik.handleChange}
            error={formik.touched.slug && Boolean(formik.errors.slug)}
            helperText={formik.touched.slug && formik.errors.slug}
            fullWidth
          />
          {cardData?.basicinfo?.slug && (
            <IconButton onClick={() => setOpenSlugDialog(true)}>
              <Info />
            </IconButton>
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label={t("Prefix")}
            name="prefix"
            value={formik.values.prefix}
            onChange={formik.handleChange}
            error={formik.touched.prefix && Boolean(formik.errors.prefix)}
            helperText={formik.touched.prefix && formik.errors.prefix}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("Suffix")}
            name="suffix"
            value={formik.values.suffix}
            onChange={formik.handleChange}
            error={formik.touched.suffix && Boolean(formik.errors.suffix)}
            helperText={formik.touched.suffix && formik.errors.suffix}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label={t("Preferred Name")}
            name="preferred_name"
            value={formik.values.preferred_name}
            onChange={formik.handleChange}
            error={
              formik.touched.preferred_name &&
              Boolean(formik.errors.preferred_name)
            }
            helperText={
              formik.touched.preferred_name && formik.errors.preferred_name
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label={t("Company")}
            name="company"
            value={formik.values.company}
            onChange={formik.handleChange}
            error={formik.touched.company && Boolean(formik.errors.company)}
            helperText={formik.touched.company && formik.errors.company}
            fullWidth
            disabled={
              group_data?.company || cardData?.group?.company ? true : false
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("Department")}
            name="department"
            value={formik.values.department}
            onChange={formik.handleChange}
            error={
              formik.touched.department && Boolean(formik.errors.department)
            }
            helperText={formik.touched.department && formik.errors.department}
            fullWidth
            disabled={
              group_data?.department || cardData?.group?.department
                ? true
                : false
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label={t("Title")}
            name="job_title"
            value={formik.values.job_title}
            onChange={formik.handleChange}
            error={formik.touched.job_title && Boolean(formik.errors.job_title)}
            helperText={formik.touched.job_title && formik.errors.job_title}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label={t("Bio")}
            name="bio"
            value={formik.values.bio}
            onChange={formik.handleChange}
            error={formik.touched.bio && Boolean(formik.errors.bio)}
            helperText={formik.touched.bio && formik.errors.bio}
            fullWidth
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
      <div className="step-button-container">
        <Button disabled variant="contained" sx={{ mt: 2, alignSelf: "right" }}>
          {t("Back")}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2, alignSelf: "right" }}
        >
          {t("Next")}
        </Button>
      </div>
      <Dialog open={openSlugDialog} className="alignByLang" fullWidth>
        <DialogTitle>{t("Warning!")}</DialogTitle>
        <DialogContent>
          <p>
            {t(
              "if you want to cahnge the slug you should know that your card link will change also and the people that have your old card profile link not open ,so you need to send to them the new QR code or the new link, click Continue to change or Cancel to discard the change."
            )}
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSlugDialog(false)}>
            {t("Cancel")}
          </Button>
          <Button onClick={() => handleCloseSlugDialogWithContinue()}>
            {t("Continue")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BasicInfoForm;
