import React, { useState, useEffect } from 'react';
import { Fab, Dialog, DialogContent, Typography, Card, CardContent, CardMedia, Avatar, Stack, Box, Badge, Button, Grid, Backdrop, CircularProgress, IconButton, DialogTitle, DialogActions } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import QrCodeIcon from '@mui/icons-material/QrCode';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { styled } from '@mui/material/styles';
import CreateCardStepper from '../Components/CardStepperComponents/CreateCardStepper';
import CardQRCodeDialog from '../Components/CardQRCodeDialog';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { AURCARDAPIBASE } from '../Utility/config';
import { CloseOutlined, CloseRounded, OpenInFull } from '@mui/icons-material';
import placeholder_image from "../../src/images/placeholder_image.png";
import { useNavigate } from 'react-router-dom';
import i18next, { t } from 'i18next';

const CustomBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#DD9A63',
    color: '#fff',
    right: -3,
    top: 13,
    padding: '0 4px',
  },
}));

const Home = () => {
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [cards, setCards] = useState([]);
  const [selectedCardData, setSelectedCardData] = useState({});
  const [qrOpen, setQROpen] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cardToDelete, setCardToDelete] = useState(null);
  const navigate = useNavigate();
  const fetchCards = async () => {
    setLoading(true);

    const authToken = localStorage.getItem('auth_token');
    if (!authToken) {
      toast.error("Authentication token is missing.");
      setLoading(false);
      navigate('/login');
      return;
    }

    try {
      const response = await axios.get(`${AURCARDAPIBASE}/cards`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("Carddata",response.data.data)


      setCards(response.data.data || []);
      setLoading(false);

    } catch (error) {
      setLoading(false);

      toast.error(`Error fetching cards: ${error.response?.data?.message || error.message}`);
    }
  };

  const [lang, setLang] = useState(localStorage.getItem("lang") || "en");

  useEffect(() => {
    fetchCards();
  }, []);

  const handleOpen = () => {
    setEditFlag(false);
    setSelectedCardData({});
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleQRCodeClose = () => setQROpen(false);

  const prepareParams = (contactWaysArray) => {

    var contactWayArr = [];
    contactWaysArray.forEach((item, index) => {
      let contactWayItem = {
        "id": item.id,
        "title": item.title,
        "link": item.link
      };
      contactWayArr.push(contactWayItem);
    });
    const contactPrams = {
      "fields": contactWayArr
    };
    return contactPrams;
  };

  const handleCreateCard = (cardData) => {
    const authToken = localStorage.getItem('auth_token');
    if (!authToken) {
      toast.error("Authentication token is missing.");
      setLoading(false);
      navigate('/login');
      return;
    }
    setLoading(true);

    const fixedParams = {
      avatar: cardData?.basicinfo?.avatar,
      cover: cardData?.basicinfo?.cover,
      logo: cardData?.basicinfo?.logo,
      name: cardData?.basicinfo?.name,
      prefix: cardData?.basicinfo?.prefix,
      suffix: cardData?.basicinfo?.suffix,
      preferred_name: cardData?.basicinfo?.preferred_name,
      company: cardData?.basicinfo?.company,
      department: cardData?.basicinfo?.department,
      job_title: cardData?.basicinfo?.job_title,
      bio: cardData?.basicinfo?.bio,
      slug: cardData?.basicinfo?.slug,
      cover_border_color: cardData?.cardstyle?.cover_border_color,
      avatar_border_color: cardData?.cardstyle?.avatar_border_color,
      card_theme: cardData?.cardstyle?.card_theme,
      theme_type: cardData?.cardstyle?.theme_type,
      avatar_shape: cardData?.cardstyle?.avatar_shape,
      style_shape: cardData?.cardstyle?.style_shape,
      avatar_align: cardData?.cardstyle?.avatar_align,
      background_color: cardData?.cardstyle?.background_color,
      text_color: cardData?.cardstyle?.text_color,
      qr_size: cardData?.qrcodeconfig?.qr_size + '',
      qr_dots_color: cardData?.qrcodeconfig?.qr_dots_color,
      qr_square_color: cardData?.qrcodeconfig?.qr_square_color,
      qr_dot_style: cardData?.qrcodeconfig?.qr_dot_style,
      qr_square_style: cardData?.qrcodeconfig?.qr_square_style,
      qr_dot_square_style: cardData?.qrcodeconfig?.qr_dot_square_style,
      qr_logo: cardData?.qrcodeconfig?.qr_logo || cardData?.basicinfo?.logo,
    };

    const prepareParamsFields = prepareParams(cardData?.contactway);
    const cardParams = { ...fixedParams, ...prepareParamsFields };


    if (editFlag) {
      cardParams._method = 'put';
      cardParams.avatar_deleted = 0;
      cardParams.cover_deleted = 0;
      cardParams.logo_deleted = 0;
      cardParams.qr_logo_deleted = 0;
    }

    const headers = {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    };

    if (editFlag) {
      axios.put(`${AURCARDAPIBASE}/cards/${cardData?.id}`, cardParams, { headers })
        .then((response) => {
          toast.success("Card updated successfully!");
          fetchCards();
          setLoading(false);

        })
        .catch((error) => {
          setLoading(false);
          toast.error(`Error updating card: ${error.response?.data?.message || error.message}`);
        });
    } else {
      axios.post(`${AURCARDAPIBASE}/cards`, cardParams, { headers })
        .then((response) => {
          setLoading(false);
          toast.success("Card created successfully!");
          fetchCards();
        })
        .catch((error) => {
          setLoading(false);
          toast.error(`Error creating card: ${error.response?.data?.message || error.message}`);
        });
    }

    handleClose();
  };

  const handleEditCard = (card, cardIndex) => {
    setEditFlag(true);
    setSelectedCardData(card);
    setOpen(true);
  };

  const handleDeleteCard = async (card) => {
    const authToken = localStorage.getItem('auth_token');
    if (!authToken) {
      toast.error("Authentication token is missing.");
      navigate('/login');
      return;
    }

    setLoading(true);

    try {
      await axios.delete(`${AURCARDAPIBASE}/cards/${card.id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      toast.success("Card deleted successfully!");
      fetchCards();

    } catch (error) {
      toast.error(`Error deleting card: ${error.response?.data?.message || error.message}`);
    } finally {
      setLoading(false);
    }
    setOpenDeleteDialog(false);
  };

  const handleQRCodeOpen = (card) => {
    setSelectedCardData(card);
    setQROpen(true);
  };
  const handleOpenDeleteCardDialog = (card) => {
    setCardToDelete(card);
    setOpenDeleteDialog(true);
  }

  const handleOpenCard = (cardData) => {
    const newTab = window.open(`/qr/${cardData?.basicinfo?.slug}`, '_blank');
    newTab.cardData = cardData;
  };

  return (
    <Box padding={1} >
      <ToastContainer />
      <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box display="flex" flexDirection="column" alignItems="flex-start" marginBottom={3}>
        <Stack direction="row" alignItems="stretch" spacing={1}>
          <Typography variant="h4">{t("My Cards")}</Typography>
          <CustomBadge badgeContent={cards.length} />
        </Stack>
        <Typography className='head-font' variant="h6" color="textSecondary">
          {t("Keep Track Of Cards And Their Details.")}
        </Typography>
      </Box>

      <Grid container spacing={3} padding={3}>
        {cards.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ position: 'relative', overflow: 'visible', display: 'flex', flexDirection: 'column', height: '100%' }}>
              <CardMedia
                component="img"
                height="140"
                image={card?.basicinfo?.cover || placeholder_image}
                alt={t("Cover Image")}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: '90px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  zIndex: 1,
                }}
              >
                <Avatar

                  src={card?.basicinfo?.avatar || placeholder_image}
                  alt={t(card?.basicinfo?.name)}
                  sx={{
                    width: 80,
                    height: 80,
                    border: '3px solid white',
                  }}
                />
              </Box>
              <CardContent sx={{ textAlign: 'center', marginTop: 5, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" gutterBottom>
                  {card?.basicinfo?.prefix} {card?.basicinfo?.name} {card?.basicinfo?.suffix}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {card?.basicinfo?.job_title}
                </Typography>
                <Box sx={{ mt: 'auto' }}>
                  <Stack direction="row" justifyContent="center" marginTop={2} flexWrap="wrap" gap={1}>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<EditIcon sx={localStorage.getItem("lang") == "ar" ? { marginLeft: 1 } : true} />}
                      onClick={() => handleEditCard(card, index)}
                      sx={{ width: { xs: '100%', sm: 'auto' } }}
                    >
                      {t("Edit")}
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<DeleteIcon sx={localStorage.getItem("lang") == "ar" ? { marginLeft: 1 } : true} />}
                      onClick={() => handleOpenDeleteCardDialog(card)}
                      sx={{ width: { xs: '100%', sm: 'auto' } }}
                    >
                      {t("Delete")}
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      startIcon={<QrCodeIcon sx={localStorage.getItem("lang") == "ar" ? { marginLeft: 1 } : true} />}
                      onClick={() => handleQRCodeOpen(card)}
                      sx={{ width: { xs: '100%', sm: 'auto' } }}
                    >
                      {t("QR Code")}
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      startIcon={<OpenInFull sx={localStorage.getItem("lang") == "ar" ? { marginLeft: 1 } : true} />}
                      onClick={() => handleOpenCard(card)}
                      sx={{ width: { xs: '100%', sm: 'auto' } }}
                    >
                      {t("Open")}
                    </Button>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Fab color="primary" onClick={handleOpen} sx={{ position: 'fixed', bottom: 16, right: 16 }}>
        <AddIcon />
      </Fab>

      <CardQRCodeDialog open={qrOpen} onClose={handleQRCodeClose} cardData={selectedCardData} />

      <Dialog open={open} fullWidth maxWidth="md">
        <IconButton sx={{ width: 30, height: 30, position: "absolute", right: 10, top: 10, backgroundColor: "#1976D2" }} onClick={handleClose}>
          <CloseOutlined sx={{ color: "#FFFFFF" }} />
        </IconButton>
        <DialogContent>
          <CreateCardStepper onCreateCard={handleCreateCard} initialData={selectedCardData || {}} editFlag={editFlag} />
        </DialogContent>
      </Dialog>

      <Dialog
  className="alignByLang"
  open={openDeleteDialog}
  fullWidth
  maxWidth="sm"
  onClose={() => setOpenDeleteDialog(false)}
>
  <DialogTitle
    sx={{
      display: "flex",
      alignItems: "center",
      gap: 1,
      fontWeight: "bold",
      textAlign: "center",
      justifyContent: "center",
    }}
  >
    <DeleteIcon color="error" sx={{ fontSize: 30 }} />
    {t("Delete Card")}
  </DialogTitle>
  <DialogContent sx={{ textAlign: "center", py: 3 }}>
    <Typography
      variant="body1"
      sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1 }}
    >
      <WarningAmberIcon color="warning" />
      {t("Are you sure you want to delete this card? This action cannot be undone.")}
    </Typography>
  </DialogContent>
  <DialogActions
    sx={{
      display: "flex",
      justifyContent: "center",
      gap: 2,
      pb: 3,
    }}
  >
    <Button
      variant="outlined"
      startIcon={<CancelIcon />}
      onClick={() => setOpenDeleteDialog(false)}
      sx={{
        borderColor: "grey.500",
        color: "grey.700",
        "&:hover": {
          backgroundColor: "grey.100",
        },
      }}
    >
      {t("Cancel")}
    </Button>
    <Button
      variant="contained"
      color="error"
      startIcon={<DeleteForeverIcon />}
      onClick={() => handleDeleteCard(cardToDelete)}
    >
      {t("Delete")}
    </Button>
  </DialogActions>
</Dialog>

    </Box>
  );
};

export default Home;
