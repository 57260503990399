import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Box,
  MenuItem,
  Avatar,
  Typography,
  DialogContent,
  IconButton,
  FormControlLabel,
  Checkbox,
  Stack,
  AppBar,
  Toolbar,
} from '@mui/material';
import placeholder_image from "../../../images/placeholder_image.png";

import "../../../css/CreateCardStepper.css";
import { getBase64OfImage, getSvgImageUri } from '../../../Utility/config';
import { t } from 'i18next';

const CardStyleForm = ({ onSave, cardData, handleBack, group_data }) => {
  const enable_style_edit = cardData?.group ? group_data?.enable_style_edit || cardData?.group?.enable_style_edit || 0 : 1;
  const [imageSrcData, setImageSrcData] = useState(cardData?.basicinfo?.cover || "");
  const [enableCoverBorder, setEnableCoverBorder] = useState(false);
  const [enableProfileBorder, setEnableProfileBorder] = useState(false);
  const [showWorkInfo, setShowWorkInfo] = useState(false);
  const [showAboutMe, setShowAboutMe] = useState(false);


  const [formData, setFormData] = useState({
    avatar_shape: !enable_style_edit ? group_data?.avatar_shape : cardData?.cardstyle?.avatar_shape || group_data?.avatar_shape || 'circle',
    avatar_align: !enable_style_edit ? group_data?.avatar_align : cardData?.cardstyle?.avatar_align || group_data?.avatar_align || 'center',
    cover_border_color: !enable_style_edit ? group_data?.cover_border_color : cardData?.cardstyle?.cover_border_color || group_data?.cover_border_color || '#000000',
    avatar_border_color: !enable_style_edit ? group_data?.avatar_border_color : cardData?.cardstyle?.avatar_border_color || group_data?.avatar_border_color || '#000000',
    background_color: !enable_style_edit ? group_data?.background_color : cardData?.cardstyle?.background_color || group_data?.background_color || '#ffffff',
    text_color: !enable_style_edit ? group_data?.text_color : cardData?.cardstyle?.text_color || group_data?.text_color || '#000000',
    style_shape: cardData?.cardstyle?.style_shape || '',
    card_theme: cardData?.cardstyle?.card_theme || '',
    theme_type: cardData?.cardstyle?.theme_type || 'static'
  });

  const styles = ['shape1', 'shape2', 'shape3', 'shape4', 'shape5'];
  const cardThemes = ['theme1', 'theme2', 'theme3', 'theme4', 'theme5', "theme6", 'theme7', 'theme8', 'theme9', "theme10"];
  const shapes = ['none', 'circle', 'square', 'rectangle'];
  const alignments = ['none', 'left', 'center', 'right'];
  const [activeForm, setActiveForm] = useState("theme");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    onSave(formData);
  };

  const toggleWorkInfo = () => {
    setShowWorkInfo((prev) => !prev);
  };

  const toggleAboutMe = () => {
    setShowAboutMe((prev) => !prev);
  };

  const handleActiveFormClick = (type = "theme") => {
    setActiveForm(type);
  }

  useEffect(() => {

    getBase64OfImage(cardData?.basicinfo?.cover).then((base64) => {
      setImageSrcData(base64)
    });
  }, [imageSrcData]);


  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <Box sx={{
          width: { xs: '100%', sm: '48%' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          padding: 2,
        }}>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 2,
            }}

          >
            <Button
              style={{
                flex: 1,
                padding: "8px 16px",
                borderRadius: "0 0 0 0",

                border: "none",
                fontSize: "16px",
                fontWeight: 500,
                cursor: "pointer",

                borderColor: "#000000",
                borderWidth: 0.5,
                borderStyle: "solid",
                borderTopRightRadius: localStorage.getItem("lang") === "ar" ? 5 : 0,
                borderBottomRightRadius: localStorage.getItem("lang") === "ar" ? 5 : 0,

                borderTopLeftRadius: localStorage.getItem("lang") === "ar" ? 0 : 5,
                borderBottomLeftRadius: localStorage.getItem("lang") === "ar" ? 0 : 5
              }}
              variant={activeForm === "theme" ? "contained" : "outlined"}
              color="primary"
              onClick={() => handleActiveFormClick("theme")}
            >
              Theme
            </Button>
            <Button
              style={{
                flex: 1,
                padding: "8px 16px",
                borderRadius: "0 0 0 0",

                border: "none",
                fontSize: "16px",
                fontWeight: 500,
                cursor: "pointer",

                borderColor: "#000000",
                borderWidth: 0.5,
                borderStyle: "solid",
                borderTopRightRadius: localStorage.getItem("lang") === "ar" ? 0 : 5,
                borderBottomRightRadius: localStorage.getItem("lang") === "ar" ? 0 : 5,
                borderTopLeftRadius: localStorage.getItem("lang") === "ar" ? 5 : 0,
                borderBottomLeftRadius: localStorage.getItem("lang") === "ar" ? 5 : 0
              }}
              variant={activeForm === "custom" ? "contained" : "outlined"}
              color="secondary"
              onClick={() => handleActiveFormClick("custom")}
            >
              Custom
            </Button>
          </Box>
          {activeForm === "theme" ?
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 2,
                  width: 250,
                  margin: "auto"
                }}
              >
                <Button
                  style={{
                    flex: 1,
                    padding: "3px 6px",
                    borderRadius: "0 0 0 0",

                    border: "none",
                    fontSize: "14px",
                    fontWeight: 500,
                    cursor: "pointer",

                    borderColor: "#000000",
                    borderWidth: 0.5,
                    borderStyle: "solid",
                    borderTopRightRadius: localStorage.getItem("lang") === "ar" ? 5 : 0,
                    borderBottomRightRadius: localStorage.getItem("lang") === "ar" ? 5 : 0,

                    borderTopLeftRadius: localStorage.getItem("lang") === "ar" ? 0 : 5,
                    borderBottomLeftRadius: localStorage.getItem("lang") === "ar" ? 0 : 5
                  }}
                  variant={formData?.theme_type === "static" ? "contained" : "outlined"}
                  color="primary"
                  onClick={() => handleChange({ target: { name: "theme_type", value: "static" } })}

                >
                  Static
                </Button>
                <Button
                  style={{
                    flex: 1,
                    padding: "3px 6px",
                    borderRadius: "0 0 0 0",

                    border: "none",
                    fontSize: "14px",
                    fontWeight: 500,
                    cursor: "pointer",

                    borderColor: "#000000",
                    borderWidth: 0.5,
                    borderStyle: "solid",
                    borderTopRightRadius: localStorage.getItem("lang") === "ar" ? 0 : 5,
                    borderBottomRightRadius: localStorage.getItem("lang") === "ar" ? 0 : 5,
                    borderTopLeftRadius: localStorage.getItem("lang") === "ar" ? 5 : 0,
                    borderBottomLeftRadius: localStorage.getItem("lang") === "ar" ? 5 : 0
                  }}
                  variant={formData?.theme_type === "animation" ? "contained" : "outlined"}
                  color="secondary"
                  onClick={() => handleChange({ target: { name: "theme_type", value: "animation" } })}
                >
                  Animation
                </Button>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 2,
                    justifyContent: "center",
                    padding: 2,
                    opacity: !enable_style_edit || formData?.style_shape ? 0.5 : 1,
                    pointerEvents: !enable_style_edit || formData?.style_shape ? "none" : "auto",
                  }}
                >
                  {cardThemes.map((theme) => (
                    <Box
                      key={theme}
                      onClick={() => handleChange({ target: { name: "card_theme", value: theme } })}
                      sx={{
                        cursor: "pointer",
                        border: formData.card_theme === theme ? "3px solid #1976d2" : "2px solid #ccc",
                        borderRadius: 2,
                        padding: 1,
                        width: 80,
                        height: 80,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#f9f9f9",
                        boxShadow: formData.card_theme === theme ? "0px 4px 8px rgba(0, 0, 0, 0.2)" : "none",
                        transition: "all 0.3s ease",
                      }}
                    >
                      <img
                        src={`./themes${formData?.theme_type === "static" ? "/" : "/animation/"}${theme}.${formData?.theme_type === "static" ? "jpg" : "gif"}`} // Replace with your image path logic
                        alt={theme}
                        style={{ width: "100%", height: "100%", borderRadius: "4px", objectFit: "cover" }}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
            </>

            :
            <>
              <TextField
                select
                label={t("Profile Style Shape")}
                placeholder={t("Profile Style Shape")}
                name="style_shape"
                value={formData.style_shape}
                onChange={handleChange}
                fullWidth
                margin="normal"
              // disabled={!enable_style_edit || (formData?.card_theme) ? true : false}

              >
                <MenuItem key={""} value={""}>
                  {t("Select Profile Style Shape")}
                </MenuItem>
                {styles.map((style) => (
                  <MenuItem key={style} value={style}>
                    {style.charAt(0).toUpperCase() + style.slice(1)}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                label={t("Profile Image Shape")}
                name="avatar_shape"
                value={formData.avatar_shape}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={!enable_style_edit || (formData?.card_theme) || formData?.style_shape ? true : false}

              >
                {shapes.map((shape) => (
                  <MenuItem key={shape} value={shape}>
                    {shape.charAt(0).toUpperCase() + shape.slice(1)}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                select
                label={t("Profile Image Alignment")}
                name="avatar_align"
                value={formData.avatar_align}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={!enable_style_edit || (formData?.card_theme) || formData?.style_shape ? true : false}

              >
                {alignments.map((align) => (
                  <MenuItem key={align} value={align}>
                    {align.charAt(0).toUpperCase() + align.slice(1)}
                  </MenuItem>
                ))}
              </TextField>

              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={enableCoverBorder}
                      onChange={() => {
                        setEnableCoverBorder((prev) => !prev);
                        setFormData((prev) => ({
                          ...prev,
                          cover_border_color: !enableCoverBorder ? "#000000" : "transparent",
                        }));
                      }}
                      disabled={!enable_style_edit || (formData?.card_theme) ? true : false}

                    />
                  }
                  label={t("Enable Cover Border Color")}
                />
                <TextField
                  type="color"
                  label={t("Cover Border Color")}
                  name="cover_border_color"
                  value={formData.cover_border_color}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  disabled={!enableCoverBorder || (formData?.card_theme) ? true : false}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={enableProfileBorder}
                      onChange={() => {
                        setEnableProfileBorder((prev) => !prev);
                        setFormData((prev) => ({
                          ...prev,
                          avatar_border_color: !enableProfileBorder ? "#000000" : "transparent",
                        }));
                      }}
                      disabled={!enable_style_edit || (formData?.card_theme) ? true : false}

                    />
                  }
                  label={t("Enable Profile Border Color")}
                />
                <TextField
                  type="color"
                  label={t("Profile Border Color")}
                  name="avatar_border_color"
                  value={formData.avatar_border_color}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  disabled={!enableProfileBorder || (formData?.card_theme) ? true : false}
                />
              </div>

              <TextField
                type="color"
                label={t("Card Background Color")}
                name="background_color"
                value={formData.background_color}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={!enable_style_edit || (formData?.card_theme) ? true : false}

              />

              <TextField
                type="color"
                label={t("Text Color")}
                name="text_color"
                value={formData.text_color}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={!enable_style_edit || formData?.card_theme ? true : false}

              />
            </>
          }
        </Box>
        {/*********  design part   *********/}
        {formData?.card_theme ?

          <Box
            sx={{
              width: { xs: '100%', sm: '48%' },
              height: '100%',
              padding: 2,
              backgroundImage: `url("./themes${formData?.theme_type === "static" ? "/" : "/animation/"}${formData?.card_theme}.${formData?.theme_type === "static" ? "jpg" : "gif"}")`, // Replace with your local image path
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              position: "relative",
              display: "flex",
              justifyContent: "center",

            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.2)", // Adjust overlay opacity
                zIndex: 1,
              }}
            />

            <Box
              sx={{
                zIndex: 2,
                textAlign: "center",
                color: "#fff",
                padding: "20px",
                borderRadius: "8px",
                maxWidth: "400px",
              }}
            >
              {/* Avatar */}
              <Box
                sx={{
                  width: "80px",
                  height: "80px",
                  backgroundColor: "#fff",
                  borderRadius: "50%",
                  margin: "0 auto",
                  marginBottom: "16px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >

                <Avatar
                  src={cardData?.basicinfo?.avatar || placeholder_image}
                  alt="Profile"
                  sx={{
                    width: { xs: 100, sm: 100, md: 100 },
                    height: { xs: 100, sm: 100 },
                    border: `3px solid #FFFFFF}`,
                    borderRadius: '50%',
                  }}
                />
              </Box>

              <Typography variant="h4" fontWeight="bold">
                {cardData?.basicinfo?.prefix || ""}{" "}{cardData?.basicinfo?.name || ""}{" "}{cardData?.basicinfo?.suffix || ""}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "5px" }}>
                {cardData?.basicinfo?.job_title || ""}{cardData?.basicinfo?.company ? " @ " : ""}{cardData?.basicinfo?.company || ""}
              </Typography>

              {cardData?.basicinfo?.preferred_name && (
                <Typography variant="body1" sx={{ marginBottom: "5px" }}>
                  {"(" + cardData?.basicinfo?.preferred_name + ")" || ""}
                </Typography>
              )}

              <Stack spacing={2} sx={{ marginTop: 3 }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    padding: "12px 20px",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.3)",
                    },
                  }}
                  onClick={toggleAboutMe}
                >
                  {showAboutMe ? "Hide About Me" : "About Me"}
                </Button>

                {showAboutMe && (
                  <Box
                    sx={{
                      padding: "16px",
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                      borderRadius: "8px",
                      marginTop: "16px",
                    }}
                  >
                    <Typography variant="h6" color="#fff">
                      About Me
                    </Typography>
                    <Typography variant="body2" color="#ccc">
                      {cardData?.basicinfo?.bio || "No work info available."}
                    </Typography>

                  </Box>
                )}
              </Stack>

              <Stack spacing={2} sx={{ marginTop: 3 }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    padding: "12px 20px",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.3)",
                    },
                  }}
                  onClick={toggleWorkInfo}
                >
                  {showWorkInfo ? "Hide Work Info" : "Work Info"}
                </Button>

                {showWorkInfo && (
                  <Box
                    sx={{
                      padding: "16px",
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                      borderRadius: "8px",
                      marginTop: "16px",
                    }}
                  >
                    <Typography variant="h6" color="#fff">
                      Work Info
                    </Typography>

                    {cardData?.basicinfo?.company && (
                      <Typography variant="body1" sx={{ marginBottom: "5px" }}>
                        Company : {cardData?.basicinfo?.company || ""}
                      </Typography>
                    )}

                    {cardData?.basicinfo?.department && (
                      <Typography variant="body1" sx={{ marginBottom: "5px" }}>
                        Department : {cardData?.basicinfo?.department || ""}
                      </Typography>
                    )}

                    {cardData?.basicinfo?.job_title && (

                      <Typography variant="body1" sx={{ marginBottom: "5px" }}>
                        Job Title : {cardData?.basicinfo?.job_title || ""}
                      </Typography>
                    )}


                  </Box>
                )}
              </Stack>


              <Stack spacing={2} sx={{
                marginTop: 3,

              }}>
                {cardData?.contactway?.length > 0 && (
                  <Box >
                    <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
                      <Button
                        fullWidth
                        onClick={() => console.log("handleSaveContact")}
                        variant="contained"
                        sx={{
                          backgroundColor: "rgba(255, 255, 255, 0.2)",
                          padding: "12px 20px",
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.3)",
                          },
                        }}
                      >
                        Save Contact
                      </Button>
                    </Box>

                    <DialogContent
                      sx={{ padding: 2 }}
                    >
                      <Box
                        sx={{
                          display: 'grid',
                          gridTemplateColumns: {
                            xs: 'repeat(2, 1fr)',
                            sm: 'repeat(2, 1fr)',
                            md: 'repeat(3, 1fr)',
                          },
                          gap: { xs: 1, sm: 3 },
                          justifyContent: cardData?.contactway?.length < 3 ? 'center' : 'start',
                          maxWidth: { xs: 200, sm: 500, md: 700 },
                          margin: '0 auto',
                          maxHeight: 200
                        }}
                      >
                        {cardData?.contactway?.map((contact) => (
                          <Box
                            key={contact.name}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              textAlign: 'center',
                              padding: { xs: 1, sm: 2 },
                              borderRadius: '12px',
                              boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                              backgroundColor: 'rgba(255, 255, 255, 0.2)',
                              color: '#fff',
                              transition: 'transform 0.3s, background-color 0.3s',
                              '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                transform: 'scale(1.05)',
                                cursor: 'pointer',
                              },
                            }}
                            onClick={() => console.log("contact")}
                          >
                            <Box
                              sx={{
                                width: '60px',
                                height: '60px',
                                borderRadius: '50%',
                                overflow: 'hidden',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: 1,
                              }}
                            >
                              <img
                                src={contact.icon || placeholder_image}
                                alt={contact.name}
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                              />
                            </Box>
                            <Typography variant="body2">{contact.name}</Typography>
                          </Box>
                        ))}
                      </Box>
                    </DialogContent>


                  </Box>
                )}
              </Stack>


            </Box>
          </Box>
          :
          <Box
            sx={{
              backgroundColor: formData.background_color,
              width: { xs: '100%', sm: '48%' },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              padding: 2,
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: !formData?.style_shape ? 150 : 300,
                backgroundImage: !formData?.style_shape && !formData?.card_theme ? `url(${cardData?.basicinfo?.cover || ""})` : getSvgImageUri(imageSrcData, formData?.style_shape, formData.cover_border_color),
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: !formData?.style_shape ? 2 : 0,
                border: `${!formData?.style_shape ? "5px" : "0px"} solid ${formData.cover_border_color}`,
                marginBottom: 2,
                position: 'relative',
                padding: 1
              }}
            >
              {!formData?.style_shape ?
                <Avatar
                  src={cardData?.basicinfo?.avatar || ""}
                  alt={t("Profile")}
                  sx={{
                    width: formData.avatar_shape === 'rectangle' ? 120 : 100,
                    height: 100,
                    border: `3px solid ${formData.avatar_border_color}`,
                    borderRadius: formData.avatar_shape === 'circle' ? '50%' : formData.avatar_shape === 'square' ? '0%' : '0%',
                    position: 'absolute',
                    bottom: -40,
                    // Align based on the selected value of formData.avatar_align
                    left: formData.avatar_align === 'left' ? 20 : formData.avatar_align === 'right' ? 'auto' : '50%',
                    right: formData.avatar_align === 'right' ? 20 : 'auto',
                    transform: formData.avatar_align === 'center' ? 'translateX(-50%)' : 'none',
                  }}
                />
                :
                <>
                  <Avatar
                    src={cardData?.basicinfo?.avatar || ""}
                    alt={t("Profile")}
                    sx={{
                      width: formData.avatar_shape === 'rectangle' ? 120 : 100,
                      width: formData.style_shape ? 90 : 100,
                      height: formData.style_shape ? 90 : 100,
                      border: `0px solid ${formData?.avatar_border_color}`,
                      borderRadius: formData?.style_shape === "shape1" ? '7%' : formData?.style_shape === "shape2" ? '50%' : formData?.style_shape === "shape3" ? '50%' : formData?.style_shape === "shape4" ? '50%' : formData?.style_shape === "shape5" ? '7%' : '0%',
                      position: 'absolute',
                      bottom: formData?.style_shape === "shape1" ? 100 : formData?.style_shape === "shape2" ? 100 : formData?.style_shape === "shape3" ? 100 : formData?.style_shape === "shape4" ? -20 : formData?.style_shape === "shape5" ? 120 : "auto",//shap1
                      left: formData?.style_shape === "shape1" ? 10 : formData?.style_shape === "shape2" ? 10 : formData?.style_shape === "shape3" ? 10 : 'auto',//shap1
                      right: formData?.style_shape === "shape4" ? 20 : 'auto',

                    }}
                  />

                  <Box sx={{
                    textAlign: "center",
                    left: formData?.style_shape === "shape1" ? 0 : formData?.style_shape === "shape2" ? 0 : formData?.style_shape === "shape3" ? 0 : formData?.style_shape === "shape4" ? 0 : 'auto',
                    position: 'absolute',
                    bottom: formData?.style_shape === "shape1" ? -10 : formData?.style_shape === "shape2" ? 0 : formData?.style_shape === "shape3" ? 0 : formData?.style_shape === "shape4" ? 0 : formData?.style_shape === "shape5" ? 0 : "auto",//shap1
                  }}>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', color: formData?.text_color || '#000000' }}>
                      {cardData?.basicinfo?.name || ""}
                    </Typography>

                    <Typography variant="subtitle1" sx={{ color: formData?.text_color || '#000000', marginBottom: 2 }}>
                      {cardData?.basicinfo?.job_title || ""}
                    </Typography>
                  </Box>
                </>
              }

            </Box>

            {!formData?.style_shape && (
              <>
                <Typography variant="h5" sx={{ textAlign: formData.avatar_align, marginTop: 5, fontWeight: 'bold', color: formData.text_color }}>
                  {cardData?.basicinfo?.name || ""}
                </Typography>
                <Typography variant="subtitle1" sx={{ textAlign: formData.avatar_align, color: formData.text_color }}>
                  {cardData?.basicinfo?.job_title || ""}
                </Typography>
              </>
            )}



            {cardData?.contactway?.length > 0 ? (
              <DialogContent sx={{ padding: 2 }}>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                      xs: 'repeat(2, 1fr)',
                      sm: 'repeat(2, 1fr)',
                      md: 'repeat(3, 1fr)',
                    },
                    gap: { xs: 1, sm: 3 },
                    justifyContent: cardData?.contactway?.length < 3 ? 'center' : 'start',
                    maxWidth: { xs: 200, sm: 500, md: 700 },
                    margin: '0 auto',
                    maxHeight: 200
                  }}
                >
                  {cardData?.contactway?.map((contact) => (
                    <Box
                      key={contact.name}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        padding: { xs: 0.5, sm: 1 },
                        borderRadius: 2,
                        boxShadow: 2,
                        backgroundColor: '#f0f0f0',
                        '&:hover': {
                          backgroundColor: '#e0e0e0',
                          cursor: 'pointer',
                        },
                      }}
                    >
                      <IconButton>
                        <img src={contact.icon} alt={contact.name} style={{ width: 50, height: 50 }} />
                      </IconButton>
                      <Typography variant="body2" sx={{ marginTop: { xs: 0, sm: 1 } }}>
                        {contact.title || contact.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </DialogContent>
            ) : true}
          </Box>
        }



      </Box>
      <div className='step-button-container'>
        <Button
          onClick={() => handleBack()}
          variant="contained"
          color="primary"
          sx={{ mt: 2, alignSelf: "right" }}
        >
          {t("Back")}
        </Button>
        <Button
          onClick={handleSubmit}
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2, alignSelf: "center" }}
        >
          {t("Next")}
        </Button>
      </div>
    </>

  );
};

export default CardStyleForm;
