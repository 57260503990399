import React, { useEffect, useState } from 'react'
import { Avatar, Box, Button, Dialog, DialogContent, Fab, IconButton, MenuItem, TextField, Grid, Card, CardMedia, Typography, Stack, CardContent, Checkbox, FormControlLabel, DialogActions, Chip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { CardMembership, Close, CloseOutlined, Label, OpenInFull, People, RemoveCircle } from '@mui/icons-material';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import placeholder_image from "../images/placeholder_image.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { AURCARDAPIBASE } from '../Utility/config';
import axios from 'axios';
import { t } from 'i18next';
const Groups = () => {
    const [open, setOpen] = useState(false);
    const [members, setMembers] = useState([]);
    const [openMemberDialog, setOpenMemberDialog] = useState(false);
    const [openAddMemberDialog, setOpenAddMemberDialog] = useState(false);
    const [newMember, setNewMember] = useState({ is_admin: false, email: '' });
    const [selectedGroup, setSelectedGroup] = useState(null);

    const [groupsData, setGroupsData] = useState([]);
    const [groupData, setGroupData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [editFlag, setEditFlag] = useState(false);
    const shapes = ['none', 'circle', 'square', 'rectangle'];
    const alignments = ['none', 'left', 'center', 'right'];



    const fetchGroups = async () => {
        setLoading(true);

        const authToken = localStorage.getItem('auth_token');
        if (!authToken) {
            toast.error("Authentication token is missing.");
            setLoading(false);
            navigate('/login');
            return;
        }

        try {
            const response = await axios.get(`${AURCARDAPIBASE}/groups`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            setGroupsData(response.data.data || []);
            setLoading(false);

        } catch (error) {
            setLoading(false);

            toast.error(`Error fetching groups: ${error.response?.data?.message || error.message}`);
        }
    };

    useEffect(() => {
        fetchGroups();
    }, []);


    const navigate = useNavigate();
    const handleOpen = () => {
        setGroupData(null);
        setSelectedGroup(null);
        setEditFlag(false);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    }

    const groupSchema = Yup.object({
        // company: Yup.string().required("Company  is required")
    });

    const upadteGroupData = async (values) => {
        setLoading(true);
        const authToken = localStorage.getItem('auth_token');
        if (!authToken) {
            toast.error("Authentication token is missing.");
            setLoading(false);
            navigate('login');
            return;
        }
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
        };
        try {
            if (editFlag) {
                const response = await axios.put(`${AURCARDAPIBASE}/groups/${groupData?.id}`, values, { headers });
                if (response?.data?.success) {
                    toast.success("Group has been Updated successfully!");
                    setLoading(false);
                } else {
                    toast.error("Failed to Update");
                    setLoading(false);

                }

            } else {
                const response = await axios.post(`${AURCARDAPIBASE}/groups`, values, { headers });
                if (response?.data?.success) {
                    toast.success("Group has been Added successfully!");
                    setLoading(false);
                } else {
                    toast.error("Failed to Add");
                    setLoading(false);
                }
            }

        } catch (error) {

            if (editFlag) {
                toast.error("Failed to Update");

            } else {
                toast.error("Failed to Add");

            }
            setLoading(false);
        }
        setGroupData(null);
        setSelectedGroup(null);
        setEditFlag(false);
        fetchGroups();
        setOpen(false);
    }

    const handleEditGroup = (group) => {
        setEditFlag(true);
        setGroupData(group);
        setOpen(true);
    }

    const handleDeleteGroup = async (group) => {
        const authToken = localStorage.getItem('auth_token');
        if (!authToken) {
            toast.error("Authentication token is missing.");
            navigate('/login');
            return;
        }

        setLoading(true);

        try {
            await axios.delete(`${AURCARDAPIBASE}/groups/${group.id}`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            toast.success("Group deleted successfully!");
            fetchGroups();

        } catch (error) {
            toast.error(`Error deleting Group: ${error.response?.data?.message || error.message}`);
        } finally {
            setLoading(false);
        }
    }

    const handleOpenMember = (group) => {
        handleOpenMemberDialog(group);
    }

    const handleOpenGroup = (group) => {
        // navigate(`/groups/group?group_id=${group?.id}&group_name=${group?.name}&enable_style_edit=${group?.enable_style_edit}`)
        navigate(`/groups/group`, { state: { group } })

    }

    const handleOpenMemberDialog = (group) => {
        setSelectedGroup(group);
        setGroupData(group);
        setMembers(group?.members || []);
        setOpenMemberDialog(true);
    };

    const handleCloseMemberDialog = () => {
        setOpenMemberDialog(false);
        setSelectedGroup(null);
    };

    const handleSaveMembers = async () => {
        const authToken = localStorage.getItem('auth_token');
        if (!authToken) {
            toast.error("Authentication token is missing.");
            setLoading(false);
            navigate('login');
            return;
        }
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
        };
        try {
            const response = await axios.put(`${AURCARDAPIBASE}/groups/${groupData?.id}`, groupData, { headers });
            if (response?.data?.success) {
                fetchGroups();
                toast.success("Group Members has been Updated successfully!");
                setLoading(false);
            } else {
                toast.error("Failed to Update Group Members");
                setLoading(false);
            }

        } catch (error) {

            toast.error("Failed to Update");


            setLoading(false);
        }
        setGroupData(null);
        setSelectedGroup(null);
        setOpenMemberDialog(false);

    }

    const handleOpenAddMemberDialog = () => {
        setOpenAddMemberDialog(true);
    };

    const handleCloseAddMemberDialog = () => {
        setOpenAddMemberDialog(false);
        setNewMember({ is_admin: false, email: '' });
    };

    const handleAddMember = () => {
        if (newMember.email) {
            const updatedMembers = [...members, newMember];
            setMembers(updatedMembers);
            setGroupData({ ...groupData, members: updatedMembers });
            handleCloseAddMemberDialog();
        }
    };

    const handleRemoveMember = (member) => {
        const emailToRemove = member?.email;
        const updatedMembersList = members.filter(m => m.email !== emailToRemove);
        setGroupData({ ...groupData, members: updatedMembersList })
        setMembers(updatedMembersList);
    }

    return (
        <Box className="alignByLang">
            <ToastContainer />
            <Grid container spacing={3} padding={3}>
                {groupsData.map((group, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card sx={{ position: 'relative', overflow: 'visible', display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <CardMedia
                                component="img"
                                height="140"
                                image={group?.cover || ""}
                                alt={t("Cover Image")}
                            />
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '90px',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    zIndex: 1,
                                }}
                            >
                                <Avatar

                                    src={group?.logo || ""}
                                    alt={t("Logo")}
                                    sx={{
                                        width: 80,
                                        height: 80,
                                        border: '3px solid white',
                                    }}
                                />
                            </Box>
                            <CardContent sx={{ textAlign: 'center', marginTop: 5, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="h6" gutterBottom>
                                    {group?.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {group?.department}
                                </Typography>
                                <Box sx={{ mt: 'auto' }}>
                                    <Stack direction="row" justifyContent="center" marginTop={2} flexWrap="wrap" gap={1}>
                                        {group?.is_current_user_admin && (

                                            <>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    startIcon={<EditIcon sx={localStorage.getItem("lang")=="ar"?{marginLeft:1}:true} />}
                                                    onClick={() => handleEditGroup(group, index)}
                                                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                                                >
                                                    {t("Edit")}
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="error"
                                                    startIcon={<DeleteIcon sx={localStorage.getItem("lang")=="ar"?{marginLeft:1}:true} />}
                                                    onClick={() => handleDeleteGroup(group)}
                                                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                                                >
                                                    {t("Delete")}
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="error"
                                                    startIcon={<People sx={localStorage.getItem("lang")=="ar"?{marginLeft:1}:true} />}
                                                    onClick={() => handleOpenMember(group)}
                                                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                                                >
                                                    {t("Members")}
                                                </Button>
                                            </>
                                        )}
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            startIcon={<OpenInFull sx={localStorage.getItem("lang")=="ar"?{marginLeft:1}:true} />}
                                            onClick={() => handleOpenGroup(group)}
                                            sx={{ width: { xs: '100%', sm: 'auto' } }}
                                        >
                                            {t("Open")}
                                        </Button>
                                    </Stack>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>


            <Dialog open={open} fullWidth maxWidth="md">
                <IconButton sx={{ width: 30, height: 30, position: "absolute", right: 10, top: 10, backgroundColor: "#1976D2" }} onClick={handleClose}>
                    <CloseOutlined sx={{ color: "#FFFFFF" }} />
                </IconButton>
                <DialogContent>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            cover: groupData?.cover || "",
                            logo: groupData?.logo || "",
                            name: groupData?.name || '',
                            company: groupData?.company || '',
                            department: groupData?.department || '',
                            // job_title: groupData?.job_title || '',

                            cover_border_color: groupData?.cover_border_color || '',
                            avatar_border_color: groupData?.avatar_border_color || '',
                            avatar_shape: groupData?.avatar_shape || '',
                            avatar_align: groupData?.avatar_align || '',
                            background_color: groupData?.background_color || '',
                            text_color: groupData?.text_color || '',
                            members: groupData?.members || [],
                            enable_style_edit : groupData?.enable_style_edit || 0

                        }}
                        validationSchema={groupSchema}
                        onSubmit={(values) => {
                            upadteGroupData(values);
                        }}
                    >
                        {({ setFieldValue, values, handleChange, errors, touched }) => (
                            <Form className="group-form">

                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }} >
                                    <Box width={"50%"} textAlign="center">
                                        <input
                                            className="profile-avatar"
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            id="logoInput"
                                            onChange={(e) => {
                                                const file = e.target.files[0];
                                                if (file) {
                                                    const reader = new FileReader();
                                                    reader.onload = () => {
                                                        setFieldValue('logo', reader.result);
                                                    };
                                                    reader.readAsDataURL(file);
                                                }
                                            }}
                                        />
                                        <label htmlFor="logoInput">
                                            <Avatar
                                                src={values.logo}
                                                alt={t("Logo")}
                                                sx={{
                                                    width: { xs: 50, sm: 100 },
                                                    height: { xs: 50, sm: 100 },
                                                    cursor: 'pointer',
                                                }}
                                            />
                                        </label>
                                        <ErrorMessage name="logo" component="div" className="error-message" />

                                    </Box>
                                    <Box width={"50%"} textAlign="center">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            id="coverInput"
                                            onChange={(e) => {
                                                const file = e.target.files[0];
                                                if (file) {
                                                    const reader = new FileReader();
                                                    reader.onload = () => {
                                                        setFieldValue('cover', reader.result);
                                                    };
                                                    reader.readAsDataURL(file);
                                                }
                                            }}
                                        />
                                        <label htmlFor="coverInput">
                                            <img
                                                src={values.cover}
                                                alt={t("Cover")}
                                                style={{
                                                    width: 200,
                                                    height: 100,
                                                    cursor: 'pointer',
                                                }}
                                            />
                                        </label>
                                        <ErrorMessage name="cover" component="div" className="error-message" />

                                    </Box>
                                </Box>

                                <div className="input-row-container">
                                    <TextField
                                        fullWidth
                                        label={t("Group Name")}
                                        variant="outlined"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        margin="normal"
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                    />

                                    <TextField
                                        fullWidth
                                        label={t("Company Name")}
                                        variant="outlined"
                                        name="company"
                                        value={values.company}
                                        onChange={handleChange}
                                        margin="normal"
                                    />


                                </div>

                                <div className="input-row-container">
                                    <TextField
                                        fullWidth
                                        label={t("Department")}
                                        variant="outlined"
                                        name="department"
                                        value={values.department}
                                        onChange={handleChange}
                                        margin="normal"
                                        error={touched.department && Boolean(errors.department)}
                                        helperText={touched.department && errors.department}
                                    />

                                </div>

                                <hr />
                                <div className="input-row-container">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="enable_style_edit"
                                                checked={values.enable_style_edit || false}
                                                onChange={(e) => setFieldValue("enable_style_edit", e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                        label={t("Enable Edit")}
                                    />
                                    <ErrorMessage name="enable_style_edit" component="div" className="error-message" />
                                </div>

                                <div className="input-row-container">
                                    <TextField
                                        select
                                        label={t("Profile Image Shape")}
                                        name="avatar_shape"
                                        value={values.avatar_shape}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                    >
                                        {shapes.map((shape) => (
                                            <MenuItem key={shape} value={shape}>
                                                {shape.charAt(0).toUpperCase() + shape.slice(1)}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    <TextField
                                        select
                                        label={t("Profile Image Alignment")}
                                        name="avatar_align"
                                        value={values.avatar_align}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                    >
                                        {alignments.map((align) => (
                                            <MenuItem key={align} value={align}>
                                                {align.charAt(0).toUpperCase() + align.slice(1)}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="input-row-container">
                                    <TextField
                                        required
                                        type="color"
                                        label={t("Cover Border Color")}
                                        name="cover_border_color"
                                        value={values.cover_border_color}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        focused
                                    />

                                    <TextField
                                        required
                                        type="color"
                                        label={t("Profile Border Color")}
                                        name="avatar_border_color"
                                        value={values.avatar_border_color}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        focused
                                    />
                                </div>
                                <div className="input-row-container">

                                    <TextField
                                        required
                                        type="color"
                                        label={t("Card Background Color")}
                                        name="background_color"
                                        value={values.background_color}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        focused
                                    />

                                    <TextField
                                        required
                                        type="color"
                                        label={t("Text Color")}
                                        name="text_color"
                                        value={values.text_color}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        focused
                                    />
                                </div>

                                <Button variant="contained" type="submit" fullWidth className="update-button">
                                    {editFlag ? t("Update") : t("ADD")}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>


            <Dialog open={openMemberDialog} onClose={handleCloseMemberDialog} fullWidth maxWidth="sm">
                <IconButton sx={{ position: "absolute", top: 3, right: 3, width: { xs: "10%", sm: "5%" }, height: 30, borderWidth: 1, borderColor: "#000", borderStyle: "solid" }} onClick={handleCloseMemberDialog}>
                    <CloseOutlined color="#000" />
                </IconButton>

                <DialogContent sx={{ marginTop: 3 }}>
                    {/* <Typography variant="badge" mb={2}>Members of {selectedGroup?.name}</Typography> */}
                    <Chip
                        label={`${t("Members of")} ${selectedGroup?.name}`}
                        color="primary"
                        variant="outlined"
                        style={{ marginBottom: '16px' }}
                    />
                    <div style={{ overflow: "auto", height: "20%" }}>
                        {members.map((member, index) => (
                            <>
                                <Box key={index} display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                                    <div>{member.email}{member.is_admin ? " (admin)" : ""} </div>

                                    <IconButton onClick={() => handleRemoveMember(member)}><RemoveCircle /></IconButton>
                                </Box>
                                <hr />
                            </>

                        ))}
                    </div>

                </DialogContent>
                <DialogActions style={{ flex: 1, flexDirection: "row", justifyContent: "space-around" }}>
                    <Button onClick={handleOpenAddMemberDialog} variant="outlined" color="primary">
                        {t("Add Member")}
                    </Button>
                    <Button onClick={handleSaveMembers} variant="contained" color="primary">
                        {t("save")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openAddMemberDialog} onClose={handleCloseAddMemberDialog} fullWidth maxWidth="xs">
                <DialogContent>
                    <Typography variant="h6" mb={2}>{t("Add New Member")}</Typography>

                    <TextField
                        fullWidth
                        label={t("Email")}
                        value={newMember.email}
                        onChange={(e) => setNewMember({ ...newMember, email: e.target.value })}
                        margin="normal"
                    />

                    <FormControlLabel
                        required={false}
                        control={
                            <Checkbox
                                required={false}
                                checked={newMember.is_admin || false}
                                onChange={(e) => setNewMember({ ...newMember, is_admin: e.target.checked })}
                                color="primary"
                            />
                        }
                        label={t("Is Admin")}
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleAddMember} variant="contained" color="primary">{t("Add")}</Button>
                    <Button onClick={handleCloseAddMemberDialog} variant="outlined">{t("Cancel")}</Button>
                </DialogActions>
            </Dialog>

            <Fab color="primary" onClick={handleOpen} sx={{ position: 'fixed', bottom: 16, right: 16 }}>
                <AddIcon />
            </Fab>
        </Box>
    )
}

export default Groups